import { useTable, useExpanded, useRowSelect, useFilters, useSortBy, usePagination } from "react-table";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState, useRef } from "react";
import { TablePaginator } from "./modules/TablePaginator";
import { TableHeader } from "./modules/TableHeader";
import { TableTitle } from "./modules/TableTitle";
import { Loader } from "../Loader";
import { XIcon } from "@heroicons/react/outline";
import { Select, Dropdown, Space, Menu, Button, Tag,List } from "antd";
import { TextColumnFilter } from "./modules/ColumnFiltersUI";
import { IndeterminateCheckbox } from "./IndeterminateCheckbox";
import { getSelectedElementsFromIds, toggleAllPreviouslySelected } from "./BasicTableUtils";
import { SearchInput } from "../SearchInput";

export const BasicTable = forwardRef(({
    columns,
    data,
    disableCheck = () => false,
    onSelectionChange = () => {},
    onFilterChange = (activeFilters) => {},
    onSortChange = (activeSort) => {},
    onPaginationChange= (requestedPage) => {},
    onPageSizeChange = (pageSize) => {},
    queryOnClick = false,
    selectable=false,
    selectParents=false,
    editable=false,
    updateData=null,
    filterable=false,
    sortable=false,
    showHeader=false,
    showTitle=false,
    labelToPaginator= "",
    pagesSize = 0,
    showPaginator=false,
    showLoader= false,
    showDataStatus = false,
    isFetching = false,
    titleOptions = {
        titleText: '',
        subtitleText: '',
        buttonText: '',
        buttonOnClick: () => {}
    },
    emptyTableText = null,
    paginationMeta,
    showCount,
    showCountHeader=false,
    showCountFooter=true
}, ref) => {

    useImperativeHandle(ref, () => ({resetSelection: resetSelection, resetPagination: resetPagination}))

    const columnsMemorized = useMemo(() => columns, [])
    
    const defaultColumn = useMemo(
        () => ({
            Filter: cell => (
                queryOnClick ?
                <SearchInput
                    queryOnClick={queryOnClick}
                    debounce = {250}
                    onSubmit = {value => {
                        cell.column.setFilter(value)
                        const activeFilters = cell.columns.reduce((acc, column) => {
                          if (column.id === cell.column.id) acc.push({ id: column.id, value: value })
                          else if (column.filterValue) acc.push({ id: column.id, value: column.filterValue })
                          return acc
                        }, [])
                        onFilterChange(activeFilters)
                    }}
                    onChange = {value => {
                      cell.column.setFilter(value)
                    }}
                /> : <TextColumnFilter column={cell.column}/>
              ),
        }),
        []
    )

    const options = {
        columns: columnsMemorized,
        data: data,
        updateData,
        defaultColumn,
        defaultCanFilter: false,
        defaultCanSort: false,
        disableMultiSort: true,
        pageCount: paginationMeta?.total_pages ? paginationMeta.total_pages : 1,
        manualFilters: true,
        manualSortBy: true,
        manualPagination: true,
        autoResetSelectedRows: false,
        autoResetExpanded: false,
        autoResetFilters: false,
        autoResetSortBy: false,
        autoResetPage: false,
        autoResetRowState: false,
        autoResetGroupBy: false,
        initialState: {
            pageSize: paginationMeta?.per_page ? paginationMeta.per_page : 10,
            pageIndex: paginationMeta?.current_page ? paginationMeta.current_page-1 : 0,
            isFetching: isFetching
        },
        useControlledState: state => {
            return React.useMemo(
                () => ({
                    ...state,
                    isFetching: isFetching
                }),
                [state, isFetching]
            )
        }
    }

    /*if(editable){
        options['defaultColumn'] = {
            quantityColumn: { Cell: EditableCell }
        }
    }*/

    const tableInstance = useTable(
        options,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        hooks => {
            if(selectable) {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({getToggleAllRowsSelectedProps, state, ...rest}) => (
                            <div>
                                {/* {console.log("table state: ", state)} */}
                                {/* {console.log("rest of props: ", rest)} */}
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} disabled={state.isFetching}/>
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => disableCheck(row) ? <></> : (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ])
            }
        }
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
        state: { pageIndex, pageSize, selectedRowIds, expanded, sortBy, filters },
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        toggleAllRowsSelected,
        toggleRowSelected
    } = tableInstance

    // SEMI-MANUAL SELECTION STATE CONTROL FOR SERVER-SIDE PAGINATION
    const [pageSelectedElements, setPageSelectedElements] = useState([[]])
    const [ignoreSelectionEffect, setIgnoreSelectionEffect] = useState(false)
    useEffect(() => {
        // console.log("selectedFlatRows",selectedFlatRows)
        // console.log("selectedRowIds",selectedRowIds)

        if (ignoreSelectionEffect) return

        const selectedElements = getSelectedElementsFromIds(selectedRowIds, selectedFlatRows)
        pageSelectedElements[pageIndex] = selectedElements.filter(element => !disableCheck(element))

        setPageSelectedElements(pageSelectedElements)

        let flatSelectedElements = []
        pageSelectedElements.forEach(page => {
            page.forEach(element => {
                // console.log("elemnt", element)
                if (selectParents || element.subRows.length === 0) flatSelectedElements.push(element.original)
            })
        })
        onSelectionChange(flatSelectedElements)
    }, [selectedRowIds])

    useEffect(() => { onSortChange(sortBy) }, [sortBy])

    useEffect(() => { if (!queryOnClick) onFilterChange(filters) }, [filters])

    const onPreviousPage = async () => {
        setIgnoreSelectionEffect(true)
        await toggleAllRowsSelected(false)
        previousPage()
        onPaginationChange(pageIndex)
        toggleAllPreviouslySelected(pageIndex-1, pageSelectedElements, toggleRowSelected)
        setIgnoreSelectionEffect(false)
    }

    const onNextPage = async () => {
        setIgnoreSelectionEffect(true)
        await toggleAllRowsSelected(false)
        nextPage()
        onPaginationChange(pageIndex+2)
        toggleAllPreviouslySelected(pageIndex+1, pageSelectedElements, toggleRowSelected)
        setIgnoreSelectionEffect(false)
    }

    const onSelectRangePage = (page) => {
        onPageSizeChange(page)
     }

    const resetSelection = async () => {
        setPageSelectedElements([[]])
        await toggleAllRowsSelected(false)
    }

    const resetPagination = () => {
        gotoPage(0)
    }



    return (
        <div className="flex flex-col">
            { showTitle && 
                <TableTitle titleOptions={titleOptions}>hola</TableTitle>
            }
            {showPaginator && 
                <TablePaginator 
                    onNextPage={onNextPage}
                    onPreviousPage={onPreviousPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    onSelectRangePage = {onSelectRangePage}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    tableTitle={labelToPaginator}
                    pagesSize={pagesSize}
                    rowsCount={paginationMeta ? paginationMeta.total_count : 1}
                    showCount={showCountHeader}
                    buttonText={queryOnClick ? "Aplicar filtros" : ""}
                    buttonOnClick={() => onFilterChange(filters)}
                />
            }
            <div className="flex-grow overflow-x-auto">

                <table
                    className="relative w-full border min-w-full divide-y divide-gray-200"
                    {...getTableProps()}
                >
                    {
                        showHeader &&
                        <thead className="bg-gray-50">
                            <TableHeader
                                headerGroups= {headerGroups}
                                filterable = {filterable}
                                sortable = {sortable}
                                showDataStatus = {showDataStatus}
                                isFetching = {isFetching}
                                selectable= {selectable}
                            ></TableHeader>
                        </thead>
                    }
                    <tbody className="relative" {...getTableBodyProps()}>
                    
                    {/* <Loader show={true}></Loader> */}
                    <tr><td className="h-0 p-0 m-0"><Loader show={showLoader}></Loader></td></tr>
                    {rows.length === 0 && emptyTableText &&           
                        <tr className="h-16 text-gray-400"><td colSpan={columns.length}><div className="flex justify-center">{emptyTableText}</div></td></tr>                    
                    }
                    {rows.map((row, rowIdx)=>{
                        prepareRow(row)
                        return (
                            <tr
                                // testing: different color for expanded group
                                // className={row.isExpanded || row.depth > 0 ? "bg-blue-50" : rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                                className={rowIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                                {...row.getRowProps()}
                            >
                                {row.cells.map((cell, index) =>{
                                    // console.log(row)
                                    return (
                                        <td
                                            // colSpan={row.canExpand ? row.cells.length : 1}
                                            className={`px-6 py-4 whitespace-nowrap text-sm text-gray-${row.depth>0 ? '400':'500'} `+(row.depth > 0 && (index === (selectable ? 1 : 0)) ? ` pl-${6+row.depth*4} `:"")}
                                            {...cell.getCellProps()}>{cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            { showPaginator && 
                <TablePaginator 
                    onNextPage={onNextPage}
                    onPreviousPage={onPreviousPage}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    onSelectRangePage = {onSelectRangePage}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    tableTitle={labelToPaginator}
                    pagesSize={pagesSize}
                    rowsCount={paginationMeta ? paginationMeta.total_count : 1}
                    showCount={showCountFooter}
                />
            }
        </div>
    )
})