import { useMemo } from 'react';
import { Select } from 'antd';

export const SelectSearchInput = ({
    options= [{id: 0, name: "title"}],
    className= "",
    onChange = (value) => console.log("onChange", value),
    firstPackageInputRef=null
}) => {

    const items = useMemo(() => 
        options.map(item => ({ value: item.bar_code || item.name, label: item.name })), 
        [options]
    );

    const onChangeHandler = (value) => {
        const selected = options.find(item => item.bar_code == value || item.name == value)
        onChange(selected)
    };

    //console.log("options", options)

    return (
        <Select
            showSearch
            //optionFilterProp="label" // default: "value"
            onChange={onChangeHandler}
            //onSearch={() => console.log("onSearch:", value)}
            options={items}
            className={className}
            ref={firstPackageInputRef}
        />
    )
}