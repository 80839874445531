import { Fragment, useContext, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Button, getSkus, Loader, SearchInput } from "../../components"
import noImage  from "../../assets/no-image.png";
import { getOperatorUsers } from "../../services/userService"
import { useQuery } from "react-query"
import { UserBadgeIcon } from "../../components/Icons/UserBadgeIcon"
import { createPickingOrder, reassignPickingOrder } from "../../services"
import { Pagination, Table, Tabs } from "antd"
import { LayeredGroupIcon } from "../../components/Icons/LayeredGroupIcon"
import { TableTransfer } from "../../components/TableTransfer"
import { XIcon } from "@heroicons/react/solid"
import { ProductLotsContainer } from "./ProductLotsContainer"
import { updateReplenishmentLots } from "../../services/replenishmentServices"
import moment from 'moment';
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { isEqual } from "lodash";
import { ChevronDoubleUpIcon, TrashIcon } from "@heroicons/react/outline";

export const CreateLotsDialog = forwardRef(({
  open,
  products = [],
  lots = [],
  isModal = true,
  replenishment_products = [],
  replenishment_id = 0,
  onSuccess = () => {},
  onCancel = () => {},
}, ref) => {

  const { TabPane } = Tabs
  
  const [editingLots, setEditingLots] = useState(lots)

  // reset all when modal is opened
  useEffect(() => {
    if (open) {
      lots.forEach(lot => {
        lot.expiration_date_moment = lot.expiration_date == '' ? '' : moment(lot.expiration_date, "YYYY-MM-DD")
        const product = replenishment_products.find(rp => rp.product_id == lot.product_id)
        if (product)  lot.replenishment_product_id = product.id
      });
      setEditingLots(lots)
    }
  }, [open])

  useImperativeHandle(ref, () => ({
    handleSaveLots: onSubmit
  }))

  const onClickCancel = () => {
    onCancel()
  }

  const [busy, setBusy] = useState(false)

  const getProductLots = (product_id) => editingLots.filter(lot => lot.product_id === product_id)

  const productThumbnail = (product) => product.image_path ? process.env.REACT_APP_PLATFORM_URL + product.image_path?.thumbnail_path : noImage

  const productImage = (product) => product.image_path ? process.env.REACT_APP_PLATFORM_URL + product.image_path?.path : noImage

  const hasEmptyDates = () => editingLots.filter(lot => lot.unique_code == '').length > 0

  const hasRepeatedItems = () => {
    const lotsWithCodes = editingLots.filter(lot => !lot.deleted)
    return lotsWithCodes.length !== [...new Set(lotsWithCodes.filter(lot => lot.unique_code != '').map(lot => lot.unique_code))].length
  }

  const canSave = () => !hasEmptyDates() && !hasRepeatedItems()

  const lotsChanged = (changedLots) => {
    const product_id = changedLots[0].product_id
    let newLots = [...editingLots.filter(lot => lot.product_id != product_id)]
    newLots = newLots.concat(changedLots)
    setEditingLots(newLots)
  }

  const getAutogeneratedCode = (lot, replenishment) => {
    if(lot.expiration_date == '') return
    //`${lot.product_id}-${replenishment}-${lot.expiration_date}`
    return `${lot.product_id}-${replenishment}-${lot.expiration_date_moment.format('DDMMy')}`
  }

  const productContainers = (product, showImage = true, bordered = true, closeIcon = false) => {
    return <div key={product.id} className={`${bordered ? 'bg-gray-200 rounded-2xl shadow-md hover:shadow-lg my-6 px-4 py-5 sm:px-6 border-b-4 pb-4 border-gray-300 mb-4' : ''}`}>
      <div className="flex items-stretch">
        <div className={`flex-grow ${bordered ? '' : 'bg-gray-200  px-4 pb-4 rounded-t-xl'}`}>
            <div className="mt-3 grid grid-cols-8">
                {showImage && <div className="mx-2 col-span-1">
                  <div style = {{
                        backgroundSize:"contain",
                        backgroundPosition:"center",
                        backgroundRepeat:"no-repeat",
                        backgroundImage: `url('${productThumbnail(product)}')`
                    }}
                    className='h-20 w-20 ml-1 mr-1 rounded-xl cursor-pointer relative border-solid' onClick={() => {
                        window.open(productImage(product), "_blank")
                    }}
                    key={product.id}>
                  </div>
                </div>}
                <div className="mx-2 col-span-7">
                  <div className="mb-3"><strong className="text-xl">{product.name}</strong></div>
                  <span className="text-sm">UPC: {product.bar_code} {getSkus(product.product_codes, 'text-sm')}</span>
                </div>
                {closeIcon && <div className="m-4 flex flex-row-reverse">
                  <ChevronDoubleUpIcon onClick={onCancel} className="cursor-pointer border-2 border-gray-400 rounded-full h-6 w-6 p-1 mr-2"></ChevronDoubleUpIcon>
                </div>}
            </div>
        </div>
      </div>
      <div className={`${bordered ? "" : 'p-4'}`}>
        <strong className="text-lg text-gray-400">Lotes de producto</strong>
        <ProductLotsContainer onChange={(lots) => lotsChanged(lots)} existingLots={editingLots} product={product} replenishment_id={replenishment_id}
          replenishment_product={replenishment_products.find(rp => rp.product_id == product.id)}></ProductLotsContainer> 
      </div>
    </div>
  }

  const onSubmit = async () => {
    
    if (hasRepeatedItems()){
      alert("Hay productos con lotes repetidos")
      return
    }
    if (!canSave()){
      alert("Asigna fechas a todos los lotes para continuar")
      return
    }
    let newLots = [...editingLots.filter(lot => lot.product_id == products[0].id)]
    setBusy(true)
    let created_lots = []
    try {
      created_lots = await updateReplenishmentLots(replenishment_id, newLots);
    } catch (error) {
      console.log(error.response);
      window.alert(error.response?.data?.message)
      return
    } finally{
      setBusy(false)
    }
    //expected_arrived_at: "2022-07-06T05:00:00.000Z"

    onSuccess(created_lots)
  }

  return (
    isModal ? 
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {
          // do nothing here, avoid closing on dismiss
          // onClickCancel()
        }}
        
      >
        <div className="flex items-center justify-center min-h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative bg-white rounded-lg shadow-xl sm:max-w-5xl sm:w-full p-6 text-gray-600 my-3 overflow-hidden max-h-[length:90vh]">
              <Loader show={busy}/>
              <div className="absolute top-0 right-0 p-5 cursor-pointer" onClick={() => { onClickCancel() }}><XIcon className="h-5 w-5"/></div>
              <Dialog.Title className="text-lg text-left text-xl" >Crear lotes de productos</Dialog.Title>
              <div className="text-lg">Ingresa la fecha de expiración del lote y define el código de identificación de ese lote</div>
              {open &&
                products.map(product => productContainers(product))
              }
              <div className="pt-5 pb-5">
                  <div className="flex justify-end">
                      <Button type="secondary" className="" onClick={() => {onCancel()}}>Cancelar</Button>
                      <Button className="ml-3 " onClick={() => onSubmit()} disabled={!canSave()}>Guardar</Button>
                  </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
    :
    <>
      <div className="relative bg-white text-gray-600 my-3  border-2 shadow-lg rounded-xl border-blue-500 shadow-blue-500">
        <Loader show={busy}/>
        {
          products.map(product => productContainers(product, false, false, true))
        }
        <div className="pr-4 pb-4">
            <div className="flex justify-end">
                <Button className="ml-3 " onClick={() => onSubmit()} disabled={!canSave()}>Guardar</Button>
            </div>
        </div>
      </div>
    </>
  )
})
