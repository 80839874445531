import { DatePicker, Tooltip } from "antd"
import React from "react"
import { useRef } from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { InputField } from "../../components"
import { CloseIcon } from "../../components/Icons/CloseIcon"
import moment from 'moment';
import { InformationCircleIcon, TrashIcon } from "@heroicons/react/outline"

export const ProductLotsContainer = ({
    replenishment_id = 0,
    replenishment_product = {},
    product = null,
    existingLots = [],
    onChange
}) => {
    const [lots, setLots] = useState(existingLots.filter(lot => lot.product_id === product.id))
    const inputRef = useRef(null)
    const dispatch = useDispatch()
    const getEmptyLot = () => {
        return {
            id: 0,
            unique_code: '',
            expiration_date: '',
            expiration_date_moment: '',
            fresh_date: '',
            product_id: product.id,
            replenishment_product_id: replenishment_product.id
        }
    }

    const addEmptyLot = () => {
        let newLotsState = [...lots]
        newLotsState.push(getEmptyLot())
        setLots(newLotsState)
        onChange(newLotsState)
    }
    const removeLot = (index) => {
        if(lots.length === 1) return
        let newLotsState = [...lots]
        if(lots[index].id === 0){
            newLotsState.splice(index, 1)
        }else{
            newLotsState[index].deleted = true
        }
        setLots(newLotsState)
        onChange(newLotsState)
    }

    const showExpirationTooltip = (lot) => {
        return lot.expiration_date_moment && lot.expiration_date_moment < moment().add(product.freshness, 'days')
    }

    const getAutogeneratedCode = (index) => {
        if(lots[index].expiration_date_moment == '') return
        return `${product.id}-${replenishment_id}-${lots[index].expiration_date_moment.format('DDMMy')}`
    }

    const getInputContainers = () => {
        if(lots.length === 0){
            addEmptyLot()
        }
        return lots.map((lot, index) => {
            return !lot.deleted && <>
                <div className="flex items-stretch">
                    <div className="flex-grow">
                        <div className="mt-3 grid grid-cols-11">
                            <div className="mr-2 col-span-5">
                                <strong className="block mb-1">{showExpirationTooltip(lot) ? <Tooltip title="La fecha de expiración seleccionada es menor que el rangro de frescura, asegúrate de que ésto es correcto.">Fecha de expiración <InformationCircleIcon className="inline text-red-500 h-5 w-5"></InformationCircleIcon></Tooltip> : "Fecha de expiración"}</strong>
                                <DatePicker 
                                format="YYYY-MM-DD"
                                defaultValue={lot.expiration_date != '' ? lot.expiration_date_moment ? lot.expiration_date_moment : moment(lot.expiration_date, "YYYY-MM-DD") : null}
                                value={lot.expiration_date_moment}
                                dateRender={(current) => {
                                    const style = {};
                                    if (current && current < moment().add(product.freshness, 'days')) {
                                        style.color = 'red';
                                    }
                                    return (
                                        <div className="ant-picker-cell-inner" style={style}>
                                            {current.date()}
                                        </div>
                                    );
                                    
                                }}
                                onChange={(value) => {
                                    let newLotsState = [...lots]
                                    newLotsState[index].expiration_date_moment = value
                                    newLotsState[index].expiration_date = value.format("YYYY-MM-DD")
                                    setLots(newLotsState)
                                    onChange(newLotsState)
                                }}
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                ></DatePicker>
                            </div>
                            
                            <div className="mx-2 col-span-5">
                                <InputField
                                className="sm:col-span-3"
                                title={<Tooltip title="Se generará una sugerencia de código automática al ingresar la fecha de expiración; puedes cambiarlo si la tienda tiene un código de lote propio.">Código de lote <InformationCircleIcon className="inline h-5 w-5"></InformationCircleIcon></Tooltip>}
                                value={lot.unique_code}
                                placeholder="Localiza el lote en el producto"
                                disabled={lot.expiration_date_moment == ''}
                                onChange={(value) => {
                                    let newLotsState = [...lots]
                                    newLotsState[index].unique_code = value
                                    setLots(newLotsState)
                                    onChange(newLotsState)
                                }}
                                />
                            </div>
                            <div className="col-span-1">
                                <div className={"inset-y-0 left-0 pt-7 pl-2 flex items-center cursor-pointer " + (lots.filter(lot => !lot.deleted).length > 1 ? 'text-black-600' : 'text-gray-300')} onClick={() => removeLot(index)}>
                                    <TrashIcon className="h-5 w-5"/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>                
            </>    
        })    
    }
    return <>
        {getInputContainers()}
        <button
            type="button"
            className="mt-6 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => addEmptyLot()}
        >+ Añadir otro lote</button>
    </>
}
