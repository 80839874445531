import { useMemo } from "react";

import { RESERVED_PACKAGE_OPTIONS } from "../RecommendedPackageSection/constants";
import { getAvailablePackingOptions } from "../RecommendedPackageSection/utils";
import { orderShippingPackagesByPriority } from './utils';
import { PACKAGE_OPTIONS } from "../RecommendedPackageSection/constants";
import { SelectSearchInput } from "../../../../../components/SelectSearchInput";

const PackageSelect = ({isPackagelessOrder, hasPackagelessProduct, storePackingOptions, availableShippingPackages, onShippingPackageChange, isFragileOrder, title=null, ignoreFragileType=false, firstPackageInputRef=null}) => {
    const availableStoreShippingPackages = useMemo(() => {
        const packingOptions = getAvailablePackingOptions(storePackingOptions, isPackagelessOrder, isFragileOrder, RESERVED_PACKAGE_OPTIONS, false, true, ignoreFragileType)

        if(!isPackagelessOrder && hasPackagelessProduct){
            const packagelessOption = Object.keys(storePackingOptions)?.find(option => option === PACKAGE_OPTIONS.PACKAGELESS)

            if(packagelessOption){
                packingOptions.push(packagelessOption)
            }
        }

        return orderShippingPackagesByPriority(availableShippingPackages, packingOptions)
    },[availableShippingPackages, isFragileOrder, hasPackagelessProduct, storePackingOptions, isPackagelessOrder, ignoreFragileType])

    return (
        <div className="flex justify-between">
            {title !== null && <span className="font-medium text-lg flex items-center">{title}</span>}

            <SelectSearchInput
                options={availableStoreShippingPackages}
                className={"w-9/12"}
                onChange={onShippingPackageChange}
                firstPackageInputRef={firstPackageInputRef}
            />
        </div>
    )
}

export default PackageSelect