import { useQuery, useQueryClient } from "react-query"
import { fetchOrder } from "../../services"
import { fetchWarehouse } from "../../services/warehouseSevices";
import { GenerateReplenishmentLabel } from "../Replenishments/GenerateReplenishmentLabel"
import { Route, Switch, useHistory, useParams } from "react-router-dom"
import {
  Button,
  ConfirmDialog,
  Loader,
  PageTopBar,
  PageView,
  SearchInput,
  SlidePanel,
  TextareaField,
  InputField,
  DialogView
} from "../../components"
import { useEffect, useMemo, useState } from "react"
import { AutoComplete, Input, InputNumber, Select, Skeleton } from "antd"
import { DamagedIcon } from "../../components/Icons/DamagedIcon"
import { TrashIcon } from "../../components/Icons/TrashIcon"
import { createReturn, receiveReturn } from "../../services/returnServices"
import { SelectReturnedProductDialog } from "../Orders/CreateReturn/SelectReturnedProductDialog"
import { fetchReturn, fetchReturnID } from "../../services/returnServices"
import { getStatusDefinition, RETURNS_STATUSES, ORDER_STATUSES } from "../../components/BasicTable/StatusPillDefinitions"
import { getReturnTypeDefinition } from "./ReturnTypes"
import { RETURNS, RECEIVE_RETURN, SHOW_RECEIVING_ORDER, ORDERS_SHIPPED, SHOW_CREATING_RETURN_ORDER, CREATE_RETURN, SHOW_CREATING_RETURN_REVERSE_LOGISTICS_ORDER, CREATE_RETURN_REVERSE_LOGISTICS, REVERSE_LOGISTICS } from "../../navigation/constants"
import { ShowOrderContainer } from "../Orders/ShowOrderContainer"
import { useRef } from "react"
import { fetchProductsBySkyOrUpc, fetchProductLots } from "../../services/productServices"
import { XIcon } from "@heroicons/react/solid"
import { SearchIcon } from "@heroicons/react/outline"
import { CreateLotsDialog } from "../Replenishments/CreateLotsDialog"
import { TableImage } from "../../components/TableImage"

export const ReceiveReturnModes = {
  RECEIVE_MODE: "RECEIVE_MODE",
  CREATE_MODE: "CREATE_MODE",
  CREATE_IN_REVERSE_LOGISTICS_MODE: "CREATE_IN_REVERSE_LOGISTICS_MODE",
}

export const ReceiveReturnContainer = ({
  onSuccess = () => {},
  onCancel = () => {},
  componentMode = ReceiveReturnModes.RECEIVE_MODE,
}) => {
  const {id, warehouse_id, orderId} = useParams()
  const history = useHistory()
  const [params, setParams] = useState({warehouse_id: warehouse_id})
  const queryClient = useQueryClient()

  const [openDialogLabel, setOpenDialogLabel] = useState(false)
  const [replenishmentInfo, setReplenishmentInfo] = useState(null)
  const [invalidReturn, setInvalidReturn] = useState(false)
  const [modifiedSubmitData, setModifiedSubmitData] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  //const [showSelectReturnedProductDialog, setShowSelectReturnedProductDialog] =
  //  useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [ignoreNextModifiedSubmitData, setIgnoreNextModifiedSubmitData] =
    useState(true)
  const [submitData, setSubmitData] = useState({
    return_id: null, // Only used if "id" param exists (receiving return)
    order_id: null, // Only used if "orderId" param exists (creating return)
    notes: "", // Only used if "orderId" param exists (creating return)
    return_tracking_number: null,
    products: [],
    warehouse_id: warehouse_id
  })
  
  const [textToSearch, setTextToSearch] = useState("")
  const [messageProductsNotFound, setProductsNotFound] = useState(false)
  const [messageProductsNotFoundInOrder, setProductsNotFoundInOrder] = useState(false)
  const searchInputRef = useRef(null)
  const [productSearchIsLoading, setproductSearchIsLoading] = useState(false)
  const [showCreateLotsDialog, setShowCreateLotsDialog] = useState(false)
  const [productToCreateLot, setProductToCreateLot] = useState({})
  const [exists, setExists] = useState(false)
  const [lastScannedProduct, setLastScannedProduct] = useState({id: 0})
  const [activeLot, setActiveLot] = useState(null)
  const [foundProducts, setFoundProducts] = useState([])


  /* const {
    isLoading: isLoadingReturnID, 
    isFetching: isFetchingReturnID,
    isError: isErrorReturnID,
    error: errorReturnID,
    data: returnID,
  } = useQuery(["return_order",orderId ], () => fetchReturnID(orderId) ,{
    enabled: orderId !== undefined,
    onSuccess: fetchedData => {
      id = fetchedData.id
    }
  }) */

  const {
    isLoading,
    isFetching,
    isError,
    error,
    data: returnData,
    refetch
  } = useQuery(["return", id], () => fetchReturn(id) ,{
    enabled: id !== undefined,
    onSuccess: fetchedData => {
      setSubmitData({...submitData, return_id: fetchedData.id})
    }
  })



const [dialog, setDialog] = useState({
    loading: false,
    open: false,
    title: "",
    description: "",
    type: null
})

  const {
    isLoading: orderIsLoading,
    isFetching: orderIsFetching,
    isError: orderIsError,
    error: orderError ,
    data: orderData,
  } = useQuery(["order", orderId], () => fetchOrder(orderId), {
    enabled: orderId !== undefined,
    onSuccess: fetchedData => {
      setSubmitData({...submitData, order_id: fetchedData.id})
    }
  })

  const {
    isLoading: isLoadingWarehouse,
    isError: isErrorWarehouse,
    error: errorWarehouse,
    data: warehouse,
    isPreviousData: isPreviousDataWarehouse,    
  } = useQuery('warehouse', () => fetchWarehouse(warehouse_id), { keepPreviousData: true }) 

  useEffect(() => {
    let returnedOrder = null
    let isInvalid = true
    let isDifferentId = false
    let dataExists = false

    if(id !== undefined) {
      returnedOrder = returnData?.returned_order
      isInvalid = (returnData?.status !== "REQUESTED" && returnData?.status !== "ARRIVED_AT_WAREHOUSE" )
      isDifferentId = id !== submitData?.return_id
      dataExists = !!returnData
    } else if(orderId !== undefined) {
      let has_multipackage = orderData?.shipping_labels.filter(label => label.status == "VALID" && label.label_type == "Shipping").length > 0
      returnedOrder = orderData
      isInvalid = (orderData?.status !== "shipped") && !has_multipackage
      isDifferentId = orderData?.id !== submitData?.order_id
      dataExists = !!orderData
    }

    console.log("modifiedSubmitData", modifiedSubmitData)
    console.log("isDifferentId", isDifferentId)

    // console.log("returnData", returnData)
    if (!dataExists) {
      return
    }
    if (isInvalid) {
      setInvalidReturn(true)
    } else {
      setInvalidReturn(false)
      // console.log("returnData", returnData)
      if (!modifiedSubmitData || isDifferentId) {
        let orderProducts = []
        returnedOrder.order_lines.forEach((ol) => {
          // console.log(ol.product)
          if (ol.product.is_kit) {
            ol.product.kit_childs.forEach((kc) => {
              orderProducts.push({
                product_id: kc.child_product.id,
                quantity: kc.quantity * ol.quantity,
                label: kc.child_product.name,
                sku:
                  kc.child_product.product_codes.length > 0
                    ? kc.child_product.product_codes[0].sku
                    : null,
                upc: kc.child_product.bar_code,
                damaged: false,
              })
            })
          } else {
            orderProducts.push({
              product_id: ol.product.id,
              quantity: ol.quantity,
              label: ol.product.name,
              sku:
                ol.product.product_codes.length > 0
                  ? ol.product.product_codes[0].sku
                  : null,
              upc: ol.product.bar_code,
              damaged: false,
            })
          }
        })
        setIgnoreNextModifiedSubmitData(true)

        if(id !== undefined) {
          setSubmitData({
            ...submitData,
            return_id: returnData.id,
            return_tracking_number: returnData.returned_order?.return_label?.shipping_number,
            notes: returnData.notes,
            products: [],
          })
        } else if(orderId !== undefined) {
          setSubmitData({
            ...submitData,
            order_id: orderData.id,
            products: [],
          })
        }
      }
    }
  }, [returnData, orderData])

  useEffect(() => {
    if (!modifiedSubmitData && !ignoreNextModifiedSubmitData) {
      // console.log("modified return data")
      setModifiedSubmitData(true)
    }
    setIgnoreNextModifiedSubmitData(false)
  }, [submitData])

  useEffect(() => {
    if ( replenishmentInfo !== null) {
      setOpenDialogLabel(true);
    }
  }, [replenishmentInfo]);

  const handleQuantityChange = (value, productIndex) => {
    // console.log("value:", value)
    // console.log("index:", productIndex)
    let products = submitData.products
    products[productIndex].quantity = value
    setSubmitData({ ...submitData, products: products })
  }

  const addDamagedProduct = (productIndex) => {
    let damagedProduct = {
      ...submitData.products[productIndex],
      damaged: true,
      quantity: 1,
    }
    let products = [...submitData.products]
    if (products[productIndex].quantity > 0) {
      products[productIndex].quantity -= 1
    }
    let foundIndex = submitData.products.findIndex(
      (p) => p.damaged && p.product_id === damagedProduct.product_id
    )
    if (foundIndex === -1) {
      products.push(damagedProduct)
    } else {
      products[foundIndex].quantity += 1
    }
    setSubmitData({ ...submitData, products: [...products] })
  }

  const removeSubmitableProduct = (product) => {
    let products = submitData.products
    products = products.filter(prod => prod.product_id != product.product_id)
    setSubmitData({
      ...submitData,
      products: products,
    })
  }

  const removeProduct = (productIndex) => {
    let products = [...foundProducts]
    products.splice(productIndex, 1)
    setFoundProducts(products)
    console.log("products", products)
    /*let products = [...submitData.products]
    products.splice(productIndex, 1)
    setSubmitData({ ...submitData, products: [...products] })*/
  }

  /*const addProduct = (productDataArray) => {
    // console.log(productDataArray)
    let products = [...submitData.products]
    productDataArray.forEach((productData) => {
      let productsToAdd = []
      if (productData.is_kit && productData.kit_childs) {
        productData.kit_childs.forEach((kc) => {
          for (let index = 0; index < kc.quantity; index++) {
            productsToAdd.push({ ...kc.child_product })
          }
        })
      } else {
        productsToAdd.push({ ...productData })
      }
      // console.log("productsToAdd", productsToAdd)
      productsToAdd.forEach((productToAdd) => {
        let foundIndex = products.findIndex(
          (p) => !p.damaged && p.product_id === productToAdd.id
        )
        if (foundIndex === -1) {
          products.push({
            product_id: productToAdd.id,
            quantity: 1,
            label: productToAdd.name,
            sku:
              productToAdd.product_codes.length > 0
                ? productToAdd.product_codes[0].sku
                : null,
            upc: productToAdd.bar_code,
            damaged: false,
          })
        } else {
          products[foundIndex].quantity += 1
        }
      })
    })
    setSubmitData({ ...submitData, products: [...products] })
    setShowSelectReturnedProductDialog(false)
  }*/

  const totalProductItems = useMemo(() => {
    let count = 0
    count = submitData.products.reduce((total, product) => total + product.quantity + product.quantity_damaged, 0)
    return count
  }, [submitData])

  const uniqueSubmitableProducts = useMemo(() => {
    const submitableProducts = []
    submitData.products.map(product => {
      if(submitableProducts.findIndex(submitableProduct => submitableProduct.product_id == product.product_id) < 0) submitableProducts.push(product)
    })
    return submitableProducts
  }, [submitData])

  const onSubmitConfirm = async () => {
    const data = {
      ...submitData
    }

    // Remove unused fields, according to the operation type: Receiving or Creating return, and ensure contains IDs
    if (returnData && returnData.return_type != "RETURNED_BY_CARRIER") {
      delete data.notes
    }
    if(id !== undefined) {
      delete data.order_id

      data.return_id = returnData.id

    } else if(orderId !== undefined) {
      delete data.return_id

      data.order_id = orderData.id
    }

    const products = []
    data.products.forEach(product => {

      if(product.quantity_damaged > 0){
        const damaged_product = {...product,
          quantity: product.quantity_damaged,
          damaged: true
        }
        products.push(damaged_product)

        if(product.quantity > 0) {
          products.push({
            ...product,
            damaged: false
          })
        }
      }else{
        products.push(product)
      }
      
    })
    data.products = products
    setLoadingSubmit(true)
    try {
      let response = null
      if(id !== undefined) { response = await receiveReturn(id, data) }
      else if(orderId !== undefined) { response = await createReturn(data) }
      if (response) {
        setReplenishmentInfo(response)
      }
      setModifiedSubmitData(false)
      setIgnoreNextModifiedSubmitData(true)
      setShowConfirmDialog(false)


    } catch (error) {
      console.log(error)
      console.log(error.response?.error)
      window.alert(error)
    }
    setLoadingSubmit(false)
  }

  const formValid = useMemo(() => {
    if (submitData.products.length == 0 || foundProducts.length > 0) {
      return false
    }
    return true
  }, [submitData, foundProducts])

  const searchProductInReturnLocally = (sku) => {
    let expectedProducts = []
    let expectedProductsKits = []
    if(id !== undefined) {
      expectedProducts = returnData.returned_order.order_lines.filter(line => line?.product?.is_kit === false).map(line => line.product)
      expectedProductsKits = returnData.returned_order.order_lines.filter(line => line?.product?.is_kit === true).flatMap(line => line.product.kit_childs.map(kit_child => kit_child.child_product))

    } else if (orderId !== undefined) {
      expectedProducts = orderData.order_lines.filter(line => line?.product?.is_kit === false).map(line => line.product)
      expectedProductsKits = orderData.order_lines.filter(line => line?.product?.is_kit === true).flatMap(line => line.product.kit_childs.map(kit_child => kit_child.child_product))
    }
    expectedProducts = expectedProducts.concat(expectedProductsKits)
    const foundProduct = expectedProducts.find(product => product.bar_code == sku || product.product_codes.map(code => code.sku).includes(sku))
    if(foundProduct) return {products: [foundProduct]}
    else return null
  }

  const handleSearchChange = async (val) => {
    console.log("Search changed")
    setProductsNotFound(false)
    setProductsNotFoundInOrder(false)
    setproductSearchIsLoading(true)
    let search_res = null
    if (val !== ""){
        //If product is already in counting process, don't look for it again in the API
        search_res = searchProductInReturnLocally(val)
        console.log("warehouse", warehouse)
        if (search_res === null && warehouse.country == "BR") {
          setProductsNotFoundInOrder(true)
          setproductSearchIsLoading(false)
          return null
        }

        if(search_res === null) {
          const storeId = id !== undefined ? returnData.returned_order.store.id : orderData.store.id
          search_res = await fetchProductsBySkyOrUpc({search: val, is_kit: false, store_id: storeId})
        }

        console.log("search_res")
        console.log(search_res)
        
        setproductSearchIsLoading(false)
        if (search_res === null || search_res.products.length != 1) {
            //setFoundProduct(null)
            setExists(false)
            if (val === ""){
                setProductsNotFound(false)
            }
            console.log("val", val)
            setProductsNotFound(true)
            setLastScannedProduct({id: 0})
            
        } else {
            let foundProduct = search_res.products[0]
            let lots = []
            if(foundProduct.has_expiration) lots = await fetchProductLots(foundProduct.id)
            lots.map(l => {
              l.value = l.unique_code
              l.label = l.unique_code
            })
            if(lastScannedProduct.id != foundProduct) {
                /*let scannedProductLots = data.replenishment.lots.filter(lot => lot.product_id === search_res.product.id)
                if(!scannedProductLots.map(lot => lot.id).includes(activeLot))
                    setActiveLot(null)*/
            }
            setLastScannedProduct(foundProduct)
            if (search_res.exists && search_res.exists === true) {
                setExists(true)
            } else {
                setExists(false)
            }
            let newFoundProducts = [...foundProducts]
            if(foundProducts.findIndex(product => product.product_id == foundProduct.id) == -1){
              newFoundProducts.push({
                id: foundProduct.id,
                product_id: foundProduct.id,
                product_codes: foundProduct.product_codes,
                quantity: 0,
                quantity_damaged: 0,
                label: foundProduct.name,
                sku:
                foundProduct.product_codes.length > 0
                    ? foundProduct.product_codes[0].sku
                    : null,
                upc: foundProduct.bar_code,
                has_expiration: foundProduct.has_expiration,
                damaged: false,
                product_image_path: foundProduct.product_image_path,
                lots: lots, 
                selected_lot: null,
                selecting_lot: false
              })
            }else{
                /*let availableLots = data.replenishment.lots.filter(lot => lot.product_id === search_res.product.id)
                let newActiveLot = activeLot
                if(availableLots.length == 1){
                    newActiveLot = availableLots[0].id
                    setActiveLot(newActiveLot)
                }
                let foundProductIndex = newFoundProducts.findIndex(product => product.id === search_res.product.id && newActiveLot == product.lot?.id)
                if(foundProductIndex == -1){
                    alert("Éste producto tiene más de un lote, selecciona uno para continuar")
                }else{
                    newFoundProducts[foundProductIndex].insertQuantity += 1
                    //animate()
                }*/
            }
            setProductsNotFound(false)
            setFoundProducts(newFoundProducts)
            searchInputRef.current?.resetInput()
        }
        setproductSearchIsLoading(false)
    }
}

const handleAddToReturnData = (product, index, damaged = false) => {
  let submitableProducts = submitData.products
  const submitableProductIndex = submitableProducts.findIndex(submitableProduct => 
    !submitableProduct.has_expiration ? submitableProduct.product_id == product.product_id : submitableProduct.product_id == product.product_id && product.selected_lot.unique_code == submitableProduct.selected_lot.unique_code
  )
  if(submitableProductIndex == -1){
    if(damaged) product.quantity_damaged = 1
    else product.quantity = 1
    submitableProducts.push(product)
  }else{
    if(damaged) submitableProducts[submitableProductIndex].quantity_damaged++
    else submitableProducts[submitableProductIndex].quantity++
    
  }
  
  if(id !== undefined) {
    setSubmitData({
      ...submitData,
      return_id: returnData.id,
      products: submitableProducts
    })
  } else if(orderId !== undefined) {
    setSubmitData({
      ...submitData,
      order_id: orderData.id,
      products: submitableProducts
    })
  }

  removeProduct(index)
}

  const resetSearch = async () => {
    setProductsNotFound(false)
    setTextToSearch("")
  }

  const setSelectingLotMode = (index) => {
    const prods = [...foundProducts]
    prods[index].selecting_lot = true
    setFoundProducts(prods)
  }

  const removeSelectedLot = (index) => {
    const prods = [...foundProducts]
    prods[index].selected_lot = null
    prods[index].selecting_lot = false
    setFoundProducts(prods)
  }

  const setSelectedLot = (lot, index) => {
    const prods = [...foundProducts]
    prods[index].selected_lot = lot
    setFoundProducts(prods)
  }

  const handleCreateLot = (product) => {
    console.log("product", product)
    console.log("returndata", returnData)
    setProductToCreateLot(product)
    setShowCreateLotsDialog(true)
  }

  const isDamaged = (product) => {
    if(id !== undefined) {
      return returnData.expected_products && returnData.expected_products.filter(expectedProduct => expectedProduct.product_id == product.id && expectedProduct.damaged === true).length != 0
    } else if(orderId !== undefined) {
      return false
    }
  }

  const disableReceiveButton = product => (product.has_expiration && !product.selected_lot) || isDamaged(product)

  return (
    <>
      <PageView className="bg-black"
          topMenu={
            <PageTopBar>
              <div className="text-lg font-semibold">

                {id !== undefined  && returnData ? (
                  <>
                    <a className="text-gray-400" onClick={() => {
                      history.push(RETURNS.replace(':warehouse_id', warehouse_id))
                    }}>Retornos →</a> 
                    {returnData.return_type == "RETURNED_BY_CARRIER" ? (
                      <>
                        Recibir Retorno Por Paquetería
                      </>
                    ):(
                      <>
                       Recibir Orden En Devolución
                       </>
                    )}
                    
                  </>
                ): componentMode === ReceiveReturnModes.CREATE_MODE ? (
                  <>
                    <a className="text-gray-400" onClick={() => {
                      history.push(ORDERS_SHIPPED.replace(':warehouse_id', warehouse_id))
                    }}>Envios →</a> Crear Retorno
                  </>
                ): (
                  <>
                    <a className="text-gray-400" onClick={() => {
                      history.push(REVERSE_LOGISTICS.replace(':warehouse_id', warehouse_id))
                    }}>Logística Inversa →</a> Crear Retorno
                  </>
                )}
              </div>
            </PageTopBar>
          }
          childrenFullWidth={true}
          topMenuFullWidth={true}
        >
        {(isLoading || orderIsLoading)  ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) : isError || orderIsError ? (
          <>Error: {isError ? error.message : orderError.message}</>
        ) : invalidReturn ? (
          <>
            {id !== undefined ? (
              <>
                Error: Este retorno no se puede recibir. ({getStatusDefinition(returnData.status, RETURNS_STATUSES).label})
              </>
            ) : (
              <>
                Error: La orden se encuentra en un estado no elegible para retorno. ({getStatusDefinition(orderData.status, ORDER_STATUSES).label})
              </>
            )}
          </>
        ) : (
          <div>
            <div className="">
              <dl>
                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Número de orden
                  </dt>
                  {id !== undefined ? (
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <a onClick={() => {
                        history.push(
                          SHOW_RECEIVING_ORDER.replace(":returnId", id).replace(':warehouse_id', warehouse_id).replace(':id', returnData.returned_order.id)
                        )
                      }}>#{returnData.returned_order.order_number}</a>
                    </dd>
                  ) : componentMode === ReceiveReturnModes.CREATE_MODE ? (
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <a onClick={() => {
                        history.push(
                          SHOW_CREATING_RETURN_ORDER.replace(":orderId", orderData.id).replace(':warehouse_id', warehouse_id).replace(':id', orderData.id)
                        )
                      }}>#{orderData.order_number}</a>
                    </dd>
                  ) : (
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <a onClick={() => {
                        history.push(
                          SHOW_CREATING_RETURN_REVERSE_LOGISTICS_ORDER.replace(":orderId", orderData.id).replace(':warehouse_id', warehouse_id).replace(':id', orderData.id)
                        )
                      }}>#{orderData.order_number}</a>
                    </dd>
                  )}
                </div>
                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Tienda</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {id !== undefined ? returnData.returned_order.store.name : orderData.store.name}
                  </dd>
                </div>
                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Tipo de Retorno</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {getReturnTypeDefinition( id !== undefined ? returnData.return_type : "RETURNED_BY_CARRIER" ).label}
                  </dd>
                </div>
                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Guía de retorno</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    { returnData  && returnData.returned_order && returnData.returned_order.return_label ? returnData.returned_order.return_label.shipping_number : 
                     <InputField
                     className="sm:col-span-3"
                     title="Guía de retorno"
                     onChange={(val) => setSubmitData({ ...submitData, return_tracking_number: val })}
                 /> }
                  </dd>
                </div>
                <div className="even:bg-gray-50 bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Notas</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    
                    {id !== undefined && returnData  && returnData.return_type != "RETURNED_BY_CARRIER" ? submitData.notes :(
                      <TextareaField  value ={submitData.notes} onChange={(val) => setSubmitData({ ...submitData, notes: val })} />
                    )}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dd className="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-3">
                    <div className="text-lg font-bold mb-4">Escanea para recibir los productos retornados</div>
                    <div className="mb-10 text-gray-500">También deberás registrar a qué lote pertenece (en caso de ser un producto con lote).</div>
                  </dd>
                  <dd className="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-1">
                    <SearchInput debounce="30" dependencies={[foundProducts, submitData]} inputValue={textToSearch} className="w-full my-2" onChange={handleSearchChange} onReset={() => {resetSearch()}} ref={searchInputRef}/>
                  </dd>
                  <dd className="mt-1 pt-4 text-sm text-gray-400 sm:mt-0 sm:col-span-2">
                    {messageProductsNotFound && "No se encontraron productos"}
                    {messageProductsNotFoundInOrder && "Este produto não pertence ao pedido"}
                  </dd>
                </div>
                <div className="bg-white pb-5 w-full">
                  <div className="w-full text-left">
                    <div className="bg-gray-50 px-6">
                      <div className="text-gray-500 uppercase grid grid-cols-12">
                        <div className="py-3">Imagen</div>
                        <div className="py-3 col-span-2">Producto</div>
                        <div className="col-span-2 py-3">UPC</div>
                        <div className="col-span-2 py-3">SKU</div>
                        <div className="col-span-2 py-3">Lote</div>
                        <div className="py-3">Piezas retornadas sin daños</div>
                        <div className="py-3">Piezas retornadas con daños</div>
                      </div>
                    </div>
                    
                    <div className="p-3">
                      {foundProducts.map((product, index) => (
                        <div className="text-gray-500 grid grid-cols-12 border rounded-lg mb-3"
                          key={
                            product.damaged
                              ? String(product.product_id) + "D"
                              : String(product.product_id)
                          }
                        >
                          <div className="p-3 py-6">
                            <TableImage
                              thumbnail_image= {product.product_image_path?.thumbnail_path}
                              regular_image= {product.product_image_path?.path}
                            ></TableImage>
                          </div>
                          <div className="p-3 py-6 col-span-2">
                            {product.label}{" "}
                            {product.damaged && (
                              <i className="text-red-500">(Piezas Dañadas)</i>
                            )}
                          </div>
                          <div className="col-span-2 py-6">{product.upc ? product.upc : "Sin UPC"}</div>
                          <div className="col-span-2 py-6">
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mx-1">
                              {product.sku}
                            </span>
                          </div>
                          <div className="py-6 col-span-2">
                            {product.has_expiration ? <>
                                {product.selected_lot && 
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-800 text-white mx-1">{product.selected_lot.unique_code}
                                  <a onClick={() => removeSelectedLot(index)}><XIcon className="text-white ml-1 h-4 w-4" aria-hidden="true" /></a>
                                  </div>}
                                {!product.selected_lot && 
                                  <button onClick={() => setSelectingLotMode(index)} className="rounded-xl border-4 items-center border-gray-400 w-4/5 p-3 text-md mb-4">Selecciona o crea el lote del producto retornado.</button>}
                                {!product.selected_lot && product.selecting_lot && <AutoComplete dropdownClassName=""
                                  className="w-full rounded-t-xl border-2 border-gray-400 bg-gray-100"
                                  defaultActiveFirstOption={false}
                                  options={product.lots} 
                                  autoFocus={true}
                                  filterOption={true}
                                  onSelect={(value, option) => {
                                    setSelectedLot(option, index)
                                  }}
                                  notFoundContent={
                                    <Button className="px-2 has-tooltip" dense onClick={() => handleCreateLot(product)}>
                                      <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                        Si no encuentras el lote del producto puedes crear uno.
                                      </span>
                                      Crear un nuevo lote
                                    </Button>
                                  }>
                                  <Input size="large" bordered={false}  placeholder="Buscar lote" className="placeholder-black" prefix={<SearchIcon className="text-black mr-1 h-4 w-4" aria-hidden="true" />}/>
                                </AutoComplete> }
                            </> : 'No requiere lote'}
                            
                            
                          </div>
                          <div className="py-6">
                            <Button className={`px-2 ${disableReceiveButton(product) ? 'has-tooltip' : ''}`} dense disabled={disableReceiveButton(product)} onClick={() => handleAddToReturnData(product, index)}>
                              <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                {isDamaged(product) ? 
                                "El producto está marcado como dañado en el retorno" :
                                product.has_expiration ? <>Para poder recibir éste producto,<br/>primero debes seleccionar <strong>a qué<br/>lote pertenece</strong>.</> : ''
                              }
                              </span>
                              Recibir
                            </Button>
                          </div>
                          <div className="py-6">
                            <Button className="px-2 has-tooltip" disabled={product.has_expiration && !product.selected_lot} dense type="danger" onClick={() => handleAddToReturnData(product, index, true)}>
                              {product.has_expiration && !product.selected_lot && <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                Para poder recibir éste producto,<br/>primero debes seleccionar <strong>a qué<br/>lote pertenece</strong>.
                              </span>}
                              Dañado
                            </Button>
                          </div>
                          <div className="py-6 text-right pr-3">
                            <Button className="px-2 has-tooltip ml-2" dense type="secondary" onClick={() => removeProduct(index)}>
                              <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                Quitar producto
                              </span>
                              <TrashIcon className="w-4 h-4" />
                            </Button>
                          </div>
                        </div>
                      ))}
                      {productSearchIsLoading && <Skeleton active title={false}></Skeleton>}
                    </div>
                  </div>
                </div>

                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dd className="mt-1 text-sm text-gray-400 sm:mt-0 sm:col-span-3">
                    <div className="text-lg font-bold mb-4">Productos recibidos del retorno</div>
                  </dd>
                </div>
                <div className="bg-white pb-5 w-full">
                  <div className="w-full text-left">
                    <div className="bg-gray-50 px-6">
                      <div className="text-gray-500 font-sm uppercase grid grid-cols-12">
                      <div className="py-3">Imagen</div>
                        <div className="py-3 col-span-2">Producto</div>
                        <div className="py-3 col-span-2">UPC</div>
                        <div className="py-3">SKU</div>
                        <div className="py-3">Piezas recibidas</div>
                        <div className="col-span-2 py-3">Lote</div>
                        <div className="py-3">Piezas retornadas sin daños</div>
                        <div className="py-3">Piezas retornadas con daños</div>
                      </div>
                    </div>
                    <div className="p-3">
                      {uniqueSubmitableProducts.map(submitableProduct => {
                        return <>
                          <div className="text-gray-500 grid grid-cols-12 border rounded-lg mb-3 my-6"
                            key={
                              submitableProduct.damaged
                                ? String(submitableProduct.product_id) + "D"
                                : String(submitableProduct.product_id)
                            }
                          >
                            <div className="p-3 py-6">
                              <TableImage
                                thumbnail_image= {submitableProduct.product_image_path?.thumbnail_path}
                                regular_image= {submitableProduct.product_image_path?.path}
                              ></TableImage>
                            </div>
                            <div className="p-3 py-6 col-span-2">
                              {submitableProduct.label}{" "}
                              {submitableProduct.damaged && (
                                <i className="text-red-500">(Piezas Dañadas)</i>
                              )}
                            </div>
                            <div className="py-6 col-span-2">{submitableProduct.upc ? submitableProduct.upc : "Sin UPC"}</div>
                            <div className="py-6">
                              <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mx-1">
                                {submitableProduct.sku}
                              </span>
                            </div>
                            <div className="py-6">
                              {submitData.products.filter(product => product.product_id == submitableProduct.product_id)
                                .reduce((total, product) => total + product.quantity + product.quantity_damaged, 0)
                              }
                            </div>
                            <div className="col-span-5">
                              {submitData.products.filter(product => product.product_id == submitableProduct.product_id).map((product, index) => {
                                return <div className={`grid grid-cols-5 mr-3 ${product.has_expiration && submitData.products.filter(product => product.product_id == submitableProduct.product_id).length > 1 ? 'border rounded-lg my-3' : ''}`}>
                                    <div className="py-6 pl-1 col-span-2">
                                      {product.has_expiration ?
                                        <div className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-800 text-white mx-1">{product.selected_lot.unique_code}</div> :
                                        'No requiere lote'
                                      }
                                    </div>
                                    <div className="py-6">
                                      {product.quantity}
                                    </div>
                                    <div className="py-6">
                                    {product.quantity_damaged}
                                    </div>
                                    <div className="py-6 text-right pr-3">
                                      <Button
                                        className="px-2 has-tooltip ml-2"
                                        dense
                                        type="secondary"
                                        onClick={() => removeSubmitableProduct(product)}
                                      >
                                        <span className="tooltip rounded shadow-lg p-1.5 bg-black text-white -mt-12">
                                          Quitar producto
                                        </span>
                                        <TrashIcon className="w-4 h-4" />
                                      </Button>
                                    </div>
                                  </div>
                              })}
                            </div>
                            
                          
                        </div>
                          </>
                      })
                        
                      }
                    </div>
                  </div>
                </div>
              </dl>
            </div>
            <div className="flex place-content-end pb-3 px-3">
              <Button
                className="mr-2"
                onClick={() => {
                  history.push(RETURNS.replace(':warehouse_id', warehouse_id))
                }}
                type="secondary"
              >
                Cancelar
              </Button>
              <Button
                disabled={!formValid}
                className=""
                onClick={() => setShowConfirmDialog(true)}
              >
                Guardar
              </Button>
            </div>
            <ConfirmDialog
              open={showConfirmDialog}
              setOpen={(val) => setShowConfirmDialog(val)}
              cancelLabel="Cancelar"
              confirmLabel="Confirmar"
              description={`Confirma que retornaron un total de ${totalProductItems} producto${
                totalProductItems > 1 ? "s" : ""
              }.`}
              title="Confirmar Recepción de Retorno"
              loading={loadingSubmit}
              onConfirm={() => onSubmitConfirm()}
            />
          </div>
        )}
        <CreateLotsDialog open={showCreateLotsDialog} 
          onSuccess={(lots) => {
            console.log("Created lots", lots)
            setShowCreateLotsDialog(false)
            const products = [...foundProducts]
            const index = products.findIndex(product => product.product_id == productToCreateLot.id)
            products[index].lots = [...products[index].lots,...lots]
            products[index].selected_lot = lots[0]
            refetch()
            setFoundProducts(products)
          }}
          onCancel={()=>setShowCreateLotsDialog(false)}
          lots={[]} products={[productToCreateLot]} replenishment_products={[]} replenishment_id={0}
        ></CreateLotsDialog>
        <DialogView className="w-full" setOpen={setOpenDialogLabel} open={openDialogLabel} >
          <GenerateReplenishmentLabel
            replenishment_info={replenishmentInfo}
            onCancel = {() => {
              setOpenDialogLabel(false)
              history.push(RETURNS.replace(':warehouse_id', warehouse_id))
            }}
            onSuccess = {() => {
              setOpenDialogLabel(false)
              history.push(RETURNS.replace(':warehouse_id', warehouse_id))
            }}
          />
        </DialogView>
      </PageView>
      <Switch>
        <Route exact path={SHOW_RECEIVING_ORDER}>
          <SlidePanel title="Ver Orden" referrer={RECEIVE_RETURN.replace(":id", id).replace(':warehouse_id', warehouse_id)}>
            <ShowOrderContainer />
          </SlidePanel>
        </Route>

        <Route exact path={SHOW_CREATING_RETURN_ORDER}>
          <SlidePanel title="Ver Orden" referrer={CREATE_RETURN.replace(":orderId", orderId).replace(':warehouse_id', warehouse_id)}>
            <ShowOrderContainer />
          </SlidePanel>
        </Route>

        <Route exact path={SHOW_CREATING_RETURN_REVERSE_LOGISTICS_ORDER}>
          <SlidePanel title="Ver Orden" referrer={CREATE_RETURN_REVERSE_LOGISTICS.replace(":orderId", orderId).replace(':warehouse_id', warehouse_id)}>
            <ShowOrderContainer />
          </SlidePanel>
        </Route>
      </Switch>
    </>
  )
}
