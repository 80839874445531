import { useState, useCallback, useRef, useMemo,useContext } from "react"
import { Route, Switch, useHistory, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { fetchShippedOrders } from "../../services"
import {UserContext} from "../../hooks/UserContext";
import { useDispatch, useSelector } from "react-redux"
import {
  ActionMenu,
  Button,
  ConfirmDialog,
  DialogView,
  FilterMenu,
  FormattedDate,
  PageTopBar,
  PageView,
  SlidePanel,
} from "../../components"
import { BasicTable } from "../../components/BasicTable/BasicTable"
import { REVERSE_LOGISTICS} from "../../navigation"
import { StatusPill } from "../../components/BasicTable/StatusPill"
import { ORDER_STATUSES, ORDER_STATUSES_SHIPPED, TRACKING_STATUSES } from "../../components/BasicTable/StatusPillDefinitions"
import { shipping_methods } from "../../components/BasicTable/ListShippingMethods"
import { Loader } from "../../components/Loader"
import { DateTimeRangeFilter, SelectColumnFilter, SelectColumnMultiFilter } from "../../components/BasicTable/modules/ColumnFiltersUI"
import { LabelView } from "./GenerateShipment/LabelView"
import { CREATE_RETURN_REVERSE_LOGISTICS, SHIPPED_ORDER_DETAIL_REVERSE, ORDER_DETAIL } from "../../navigation/constants"
import { CreateReturnContainer } from "./CreateReturn/CreateReturnContainer"
import { ShowOrderContainer } from "./ShowOrderContainer"
import { fetchWarehouseShippingMethods } from "../../services/warehouseSevices";
import { setShippedOrdersListPageSize } from '../../redux/pageSizeSlice';

const table_columns = [
  {
    Header: "Tienda",
    accessor: "store",
    disableFilters: false,
    disableSortBy: false,
  },
  {
    Header: "Nº DE ORDEN",
    accessor: "order_number", // accessor is the "key" in the data
    disableFilters: false,
    disableSortBy: false,
  },
  // {
  //     Header: 'REFERENCIA',
  //     accessor: '',
  //     disableFilters: true,
  //     disableSortBy: true
  // },
  {
    Header: "Nº TRACKING",
    accessor: "shipping_number", // accessor is the "key" in the data
    disableFilters: false,
    disableSortBy: true,
  },
  {
    Header: 'Estado',
    accessor: 'status', // accessor is the "key" in the data
    Filter: SelectColumnMultiFilter,
    selectFilterOptions: ORDER_STATUSES_SHIPPED.filter((status)=>status.filterable !== false).map((status)=> {
        return status.filterable === false ? null : {id: status.status, label: status.label}
    }),
    disableFilters: false,
    disableSortBy: false,
  },
  {
    Header: 'Estatus de envío',
    accessor: 'shipping_status', // accessor is the "key" in the data
    Filter: SelectColumnMultiFilter,
    selectFilterOptions: TRACKING_STATUSES.filter(status => status.filterable !== false)
        .map(status => ({id: status.status, label: status.label})),
    // searchAs: 'shipping_status',
    disableFilters: false,
    disableSortBy: false,
  },
  {
    Header: 'Canal',
    accessor: 'channel_name', 
    disableFilters: false,
    disableSortBy: true,
    Filter: SelectColumnFilter,
    selectFilterOptions: ['manual', 'meli', 'liverpool', 'walmart', 'vtex'].map((channel_type)=>{
        
        return {
            id: channel_type,
            label: channel_type
        }
    }),
  },
  {
    Header: "Fecha de envío",
    accessor: "shipping_date", // accessor is the "key" in the data
    Filter: DateTimeRangeFilter,
    disableFilters: false,
    disableSortBy: false,
    shrinkToContent: true,
  },
  {
    Header: "",
    // <>
    //     <a href="#" className="text-gray-400 hover:text-gray-500">
    //         <CogIcon className="h-10"/>
    //     </a>
    // </>,
    accessor: "actions", // accessor is the "key" in the data
    disableFilters: true,
    disableSortBy: true,
    fetchingIndicator: true,
    shrinkToContent: true,
  },
]

export const ListOrdersReverseLogisticsContainer = () => {
    const history = useHistory()
    const {user} = useContext(UserContext)
    const {warehouse_id} = useParams()
    const [openDialogLabel, setOpenDialogLabel] = useState(false)
    const [openDialogBilling, setOpenDialogBilling] = useState(false)
    const [openDialogDocument, setOpenDialogDocument] = useState(false)
    const [printInformation, setPrintInformation] = useState(false)
    const [selectOrder, setSelectOrder] = useState(false)
    const dispatch = useDispatch()
    const pageSize = useSelector((state) => state.pageSize.shippedOrdersList)
    const [searchParams, setSearchParams] = useState({
        warehouse_id: warehouse_id,
        page:1,
        per_page: pageSize,
        status: ['shipped', 'returned', 'returning'],
        order_by: "shipping_date",
        load_channel_integration: true
    })

  const createReturnSlidePanelRef = useRef(null)

  const additionalParams = {
    include_inactive: true
  }

  const {
    data: shippingMethodsData, 
    isLoading: isLoadingShippingMethods ,
    isError: isErrorShippingMethods,
    error: errorShippingMethods,
    isFetching: isFetchingShippingMethods,
    isPreviousData: isPreviousDataShippingMethods,
    refetch: refetchShippingMethods
  } = useQuery(['shipping_methods', warehouse_id], () => fetchWarehouseShippingMethods(warehouse_id, additionalParams), { 
      keepPreviousData : true,
      refetchOnWindowFocus: false
  })

  const {
    isLoading,
    isError,
    error,
    data,
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(
    ['shipped_orders',searchParams],
    () => fetchShippedOrders(searchParams),
    { keepPreviousData: true }
  )
  const userValid = useMemo(() => {
    let index = user.roles.findIndex(rol => (rol === "ROLE_SUPER_ADMIN"));
    if(index != -1) return true
    return false
  }, [user])

  const onSortChange = (orderBy) => {
    // console.log("ON_ORDER_BY",orderBy)
    if (orderBy.length > 0) {
      setSearchParams({
        ...searchParams,
        order_by: orderBy[0].id,
        order: orderBy[0].desc ? "desc" : "asc",
      })
    } else {
      if ("order_by" in searchParams) {
        searchParams.order_by = "shipping_date"
        delete searchParams.order
        setSearchParams({ ...searchParams })
      }
    }
  }

  const handlerOnClickTracking = (order) => {
    window.open(order.shipping_labels[order.shipping_labels.length-1].tracking_url)
  }

  const onFilterChange = (activeFilters) => {
    // console.log("ON_CHANGE_FILTER", activeFilters)
    memoizedColumns.forEach((col) => {
      if (searchParams[col.accessor]) {
        delete searchParams[col.accessor]
      }
    })
    let filters = []
    activeFilters.forEach((filter) => {
      filters[filter.id] = filter.value
    })
    if (!('status' in filters) || filters.status.length === 0) {
      filters.status = []
      filters.status.push('shipped', 'returned', 'returning')
    }
    setSearchParams({ ...searchParams, ...filters, page: 1 })
  }
  const print = (title, file, path, type) => {
    setOpenDialogDocument(false)
    setPrintInformation({
        ...printInformation,
        "title": title, 
        "is_file": file, 
        "type": type,
        "file": path
    })
    if (type != 'document' && file) setOpenDialogLabel(true) 
    else setOpenDialogDocument(true)
}

  const getMenuOptions = (order) => {
    const options = [
      {
        title: "Ver orden",
        clickHandler: () =>
          history.push(ORDER_DETAIL.replace(":id", order.id).replace(':warehouse_id', warehouse_id).replace(":origin", 'reverse_logistics')),
      }
    ]
     
    if (user.permissions.download_shipping_labels) {
      //const use_meli_doc_instead_of_label = 'meli' === order.channel_name && 'TREGGO' === order.shipping_method?.carrier_name && !!order.meli_shipment_id

      options.push({
        title: "Imprimir guía",
        clickHandler: () => {
          setSelectOrder(order.id)
          print("Imprimir Guía", true, selectOrder,"label")
        },
      })

    }
    let index = order.invoices.findIndex(invoice => (invoice.purpose === "SALE_INVOICE" || invoice.purpose === "DONATION_INVOICE" || invoice.purpose === "REMOVE_STOCK_INVOICE") && invoice.status === 'APPROVED')
    if(order.invoices.length>0 && index != -1 && user.permissions.download_invoices){
   
      options.push( {
        title: "Imprimir Etiqueta de Factura",
        clickHandler: () => {
          order.invoices[index].print = true
          setSelectOrder(order)
          print("Imprimir Etiqueta de Factura",false,order.invoices[index].label_pdf_file, 'label')
        }
        
      })
      options.push( {
        title: "Imprimir PDF de Factura",
        clickHandler: () => {
          order.invoices[index].print = true
          setSelectOrder(order)
          print("Imprimir PDF de Factura",false,order.invoices[index].pdf_file, 'document')
        }
        
      })
      options.push( {
        title: "Imprimir XML de Factura",
        clickHandler: () => {
          order.invoices[index].print = true
          setSelectOrder(order)
          print("Imprimir XML de Factura",false,order.invoices[index].xml_file, 'document')
        }
        
      })
    
    }

    if (order.status === "shipped" && !order.is_pick_and_ship && user.permissions.create_returns && order.shipping_status !== "shipment_delivered") {
      options.push({
        title: "Retornado por paquetería",
        clickHandler: () => {
          history.push(CREATE_RETURN_REVERSE_LOGISTICS.replace(":orderId", order.id).replace(':warehouse_id', warehouse_id))
        },
      })
      
    }
    if(order.shipping_method.shipping_name == "Internacional"){
        options.push( {
            title: "Imprimir Factura Comercial",
            clickHandler: () => {
              setSelectOrder(order.id)
              print("Imprimir Factura Comercial",true,selectOrder,"billing")
            }
            
        })
    }
    if(order.shipping_documents.length>0){
      order.shipping_documents.forEach((shipping_document, document_index) => {
        let title = "Imprimir Documento de Envío"
        if (document_index > 0) title += ` ${document_index+1}`
        options.push( {
            title: title,
            clickHandler: () => {
              shipping_document.print = true
              setSelectOrder(order)
              print(title,false,shipping_document.file,shipping_document.printing_type)
            }
            
        })
      })
    }


   

    return options
  }

  const getTableData = () => {
    return data.orders.map((order) => {
      return {
        store: <div className="">{String(order.store.name)}</div>,
        order_number: (
          <div
            className="hover:underline cursor-pointer"
            onClick={() =>
              history.push(SHIPPED_ORDER_DETAIL_REVERSE.replace(":id", order.id).replace(':warehouse_id', warehouse_id))
            }
          >
            #{String(order.order_number)}
          </div>
        ),
        status: (
          <StatusPill
            className="cursor-pointer"
            status={order.status}
            statuses={ORDER_STATUSES}
            onClick={() =>
              history.push(SHIPPED_ORDER_DETAIL_REVERSE.replace(":id", order.id).replace(':warehouse_id', warehouse_id))
            }
          />
        ),
        shipping_status: order.shipping_status ?
          <StatusPill className="cursor-pointer" status={order.shipping_status} statuses={TRACKING_STATUSES}
            onClick={() =>
              history.push(SHIPPED_ORDER_DETAIL_REVERSE.replace(":id", order.id).replace(':warehouse_id', warehouse_id))
            }
          />
          :
          <div className="italic"> Sin información </div>
        ,
        channel_name: order.channel_name,
        shipping_method:
          order.shipping_method == null
            ? "-"
            : `${order.shipping_method.carrier_name} ${order.shipping_method.shipping_name}`,
        shipping_number: order.shipping_labels[0] ? (
          <a className="" onClick={() => handlerOnClickTracking(order)}>
            {String(order.shipping_labels[order.shipping_labels.length-1].shipping_number)}
          </a>
        ) : null,
        shipping_date: <FormattedDate date={order.shipping_date} shortDate />,
        actions: (
          <ActionMenu className="float-right" items={getMenuOptions(order)} />
        ),
      }
    })
  }

  const getColumns = () => {
    if(shippingMethodsData) {
        let newColumns = [...table_columns]
        let index = newColumns.findIndex(column => column.accessor === "shipping_method")
        if(index === -1) {
            newColumns.splice(2,0,{
                Header: 'MÉTODO DE ENVÍO',
                accessor: 'shipping_method',
                disableFilters: false,
                disableSortBy: false,
                Filter: SelectColumnFilter,
                selectFilterOptions:shippingMethodsData.map((ship)=> {
                    return {
                        id: ship.carrier_name + " " + ship.shipping_name,
                        label: ship.carrier_name +" "+ ship.shipping_name
                    }
                })
            })
        }
        return newColumns
    }
    return []
  }

  const memoizedColumns = useMemo(() => getColumns(), [shippingMethodsData])
  const tableRef = useRef()

  return (
    <>
     
    <DialogView className="w-full" setOpen={setOpenDialogLabel} open={openDialogLabel} >
        <LabelView
                title={printInformation.title}
                type={printInformation.type}
                data={selectOrder}
                onCancel = {() => setOpenDialogLabel(false)}
                onPrint = {() => setOpenDialogLabel(false)}
        />
    </DialogView>
    <DialogView className="w-full" setOpen={setOpenDialogDocument} open={openDialogDocument} >
        <LabelView
                title={printInformation.title}
                file={printInformation.is_file}
                type={printInformation.type}
                data={printInformation.file}
                onCancel = {() => setOpenDialogDocument(false)}
                onPrint = {() => {

                  setOpenDialogDocument(false)
                  const non_printed_documents = selectOrder.shipping_documents.filter((shipping_documents) => !shipping_documents.print);
                  if(non_printed_documents.length>0){
                    for (const document of non_printed_documents){ 
                        const documents = selectOrder.shipping_documents.map(sd => (sd.id === document.id ? {...sd, print:true} : sd))
                        setSelectOrder({...selectOrder, shipping_documents: documents })
                        print("Imprimir Documentos de Envío",false,document.file,document.printing_type)
                        break;
                    };
                }
                }}
        />
    </DialogView>
    {/* <DialogView setOpen={setOpenDialogBilling} open={openDialogBilling} >
        <LabelView
            title={"Factura Comercial Inernacional"}
            data={selectOrder}
            type={"Billing"}
            onCancel={() => setOpenDialogBilling(false)}
            onPrint = {() => setOpenDialogBilling(false)}
        />
    </DialogView> */}

      <PageView
        topMenu={<PageTopBar>
          <div className="text-lg font-semibold">Pedidos Enviados</div>
      </PageTopBar>}
        childrenFullWidth={true}
        topMenuFullWidth={true}
      >
        {(!isPreviousData && isLoading) || (!isPreviousDataShippingMethods && isLoadingShippingMethods) ? (
          <>
            <Loader show={true}></Loader>
          </>
        ) : isError ? (
          <>Error: {error.message}</>
        ) : isErrorShippingMethods ? (
            <>Error: {errorShippingMethods.message}</>
        ):(
          <>
         
            
            
              <BasicTable
                // showTitle
                // titleOptions={{
                //     titleText: 'Órdenes',
                //     subtitleText: 'Listado de órdenes',
                //     buttonText: 'Crear Orden',
                //     buttonOnClick: () => {console.log("TITLE BUTTON CLICKED!!")}
                // }}
                showHeader
                showLoader={isFetching && isPreviousData}
                columns={memoizedColumns}
                filterable
                sortable
                showPaginator
                data={getTableData()}
                onFilterChange={(activeFilters) => onFilterChange(activeFilters)}
                onSortChange={(activeSort) => onSortChange(activeSort)}
                paginationMeta={data?.meta}
                labelToPaginator="Pedidos"
                pagesSize = {searchParams.per_page}
                onPaginationChange= { (requestedPage) => { setSearchParams({...searchParams, page: requestedPage}) } }
                onPageSizeChange= { (pageSize) => {
                    setSearchParams({...searchParams, per_page: pageSize, page: 1})
                    dispatch(setShippedOrdersListPageSize(pageSize))
                    tableRef.current.resetPagination()
                }}
                showDataStatus
                isFetching={isFetching}
                emptyTableText="No hay pedidos que mostrar."
                ref={tableRef}
              />
           
            
            {/* <>
              El acceso a estos datos está restringido.
            </> */}
        
          
        </>
        )}
      </PageView>
      <Switch>
        <Route exact path={SHIPPED_ORDER_DETAIL_REVERSE}>
          <SlidePanel title="Ver Orden" referrer={REVERSE_LOGISTICS.replace(':warehouse_id', warehouse_id)}>
            <ShowOrderContainer />
          </SlidePanel>
        </Route>
      </Switch>
    </>
  )
}
