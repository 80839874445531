export const pt = {
    general: {
        product: {
            insert: "Enxerto",
        }
    },

    inputPlaceholder: {
        pdfFiles: "Clique aqui ou arraste seus arquivos PDF.",
        addImage: "Clique aqui ou arraste a imagem.",
        select: "Selecionar",
        required_field: "Campo obrigatório"
    },
    claims:{
        reimbursables:{
            cost_cubbo: "Custo Operacional Cubbo",
            cost_shipping: "Custo de guia do pedido",
            product_value: "Valor indicado do produto",
            cost_shipping_replace: "Custo de guia para enviar a reposição do pedido",
            cost_shipping_reposition: "Custo do guia para enviar reposição de pedidos",
            return_cost: 'Custo da guia de devolução do produto errado'
        },
        motives:{
            delivery_delay: 'Pedido foi coletado pela transportadora mas está sem movimentação.',
            false_delivery: 'O status do pedido indica que o pedido foi entregue, mas meu cliente não o recebeu.',
            package_stolen: 'O status do pedido indica que foi roubado.',
            package_lost: 'O status do pedido indica que foi extraviado ou roubado.',
            damaged_product: 'Pedido foi entregue avariado.',
            incorrect_content: 'Pedido entregue não corresponde ao que o cliente encomendou.',
            none_of_the_above: 'Nenhuma das opções acima.',
            missing_products: 'Faltaram produtos no pedido',
            unwanted_products: 'Produtos não encomendados foram recebidos',
            wrong_order: 'Meu cliente recebeu um pedido completo que não era dele.',
            paid_product: '...algo que meu cliente comprou.',
            promotional_product: '...algo que deveria ter sido enviado como um produto promocional.'
        },
        motive_names: {
            unwanted_products: "Producto incorrecto",
            missing_products: "Producto faltante",
            wrong_order: "Guía cruzada",
            operations_others: "Otros - Operaciones",
            damaged_product: "Dañado",
            incorrect_charge: "Mal cobrado",
            other: "Otro - Área del Comercial",
            origin_error: "Error de origen",
            package_lost: "Perdido",
            damaged_product: "Dañado",
            delivery_delay: "Demora en entrega",
            false_delivery: "Entrega Incorrecta",
            package_stolen: "Robo",
            delivery_others: "Otros - Paquetería",
            missing_products: "Producto faltante"
        },
        operations:{
            unwanted_products: {
                description: "Producto incorrecto",
                recomendation: "Lembramos que pode duplicar a encomenda com incidência e ajustar os produtos a reenviar para enviar ao seu cliente os produtos pendentes.",
            },
            missing_products: {
                description: "Producto faltante",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",
            },
            wrong_order: {
                description: "Guía cruzada",
                recomendation: "Atenção: Acesse o link para realizar corretamente o reenvio de um pedido que sofreu algum incidente:",
                url: "https://scribehow.com/shared/Como_gerar_Reenvio_de_Pedido__QPK47MegT3qhfXZB7Wcrog"
            }, 
            operations_others:{
                description: "Otros - Operaciones",
                recomendation: "",

            }

        },
        commercial:{
            unwanted_products: {
                description: "Producto incorrecto",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",
            },
            wrong_order: {
                description: "Guía cruzada",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",
            }, 
            operations_others:{
                description: "Otros - Operaciones",
                recomendation: "",
            },
            damaged_product:{
                description: "Dañado",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",
            },
            package_lost:{
                description: "Perdido",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",

            }, 
            delivery_delay:{
                description: "Demora en entrega",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",

            }, 
            false_delivery:{
                description: "Entrega Incorrecta",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",

            }, 
            package_stolen:{
                description: "Robo",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",

            },
            missing_products_delivery: {
                description: "Producto faltante - Paquetería",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",
            },
            missing_products_operations: {
                description: "Producto faltante - Operaciones",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",
            },
        },
        client:{
            origin_error:{
                description: "Error de origen",
                recomendation: "",

            },
            other:{
                description: "Otro - Área del cleinte",
                recomendation: "",

            }
        },
        delivery:{
            package_lost:{
                description: "Perdido",
                recomendation: "Atenção: Acesse o link para realizar corretamente o reenvio de um pedido que sofreu algum incidente:.",
                url: "https://scribehow.com/shared/Como_gerar_Reenvio_de_Pedido__QPK47MegT3qhfXZB7Wcrog"
            }, 
            damaged_product:{
                description: "Dañado",
                recomendation: "Lembramos que você pode duplicar o pedido com incidência e ajustar os produtos a serem encaminhados para enviá-los ao seu cliente e proporcionar uma melhor experiência.",

            }, 
            delivery_delay:{
                description: "Demora en entrega",
                recomendation: "Relembramos que pode duplicar a encomenda para enviar os seus produtos ao seu cliente e iniciar a devolução da encomenda com uma incidência para a devolver ao armazém caso seja recuperada.",

            }, 
            false_delivery:{
                description: "Entrega Incorrecta",
                recomendation: "Lembramos que pode duplicar a encomenda com incidência para fazer chegar os seus produtos ao seu cliente.",

            }, 
            package_stolen:{
                description: "Robo",
                recomendation: "Lembramos que você pode duplicar o pedido com um incidente para enviar ao seu cliente os produtos que ele pediu e proporcionar uma melhor experiência.",

            },
            delivery_others:{
                description: "Otros - Paquetería",
                recomendation: "",

            }
        },
        html:{
            rejected:{
                message_initial: "Tivemos que recusar a disputa do pedido",
                category: "categorizado como",
                apologies: "lamentamos o inconveniente.",
                reazon: "O motivo da rejeição é porque",
                evidence: "Anexado a este e-mail, compartilhamos as evidências que apoiam esta decisão.",
                recommendation: "Para proporcionar a melhor experiência possível ao seu cliente, recomendamos você",
                contact: "Você pode entrar em contato conosco pelo chat da plataforma se precisar de mais detalhes.",
                farewell:"Será um prazer atendê-lo",
                sender:"Cubbo"
            },
            accepted:{
                message_initial: "Aceitamos a disputa do pedido",
                category: "categorizado como",
                apologies: "lamentamos o inconveniente.",
                resolution: "Você verá o valor a ser reembolsado refletido em sua próxima fatura como um crédito a favor. Estes são os conceitos considerados para o reembolso:",
                contact: "Você pode entrar em contato conosco pelo chat da plataforma se precisar de mais detalhes.",
                farewell:"Será um prazer atendê-lo",
                sender:"Cubbo"
            }
        },
        status:{
            accepted: "Aceptado",
            rejected: "Rechazado"
        },
        messages:{
            none : "Nenhum",
            without_reason: "Sim motivo",
            success_message: "A disputa foi resolvida com sucesso",
            success_update_message: "A disputa foi atualizada.",
            error_message: "Erro ao salvar disputa",
        }
    },

    formatted_relative_time: {
        today: "Hoje",
        yesterday: "Ontem",
        tomorrow: "Amanhã",
        date: "{{day}} de {{month}}",
        from_now: "Há {{days}} dias"
    },

    orders: {
        filters: {
            order_number: "Número do pedido",
            paste_order_numbers: "Cole os números de os pedidos",
            paste_shipping_numbers: "Cole os números de os rastreamentos",
            shipping_number: "Etiqueta de envio",
            channel_name: "Canal de venda",
            shipping_method: "Método de envio",
            status: "Estado do pedido",
            shipping_status: "Estado de envio",
            shipping_date: "Data de envio",
            channel_name_placeholder: "Selecione um canal de venda",
            shipping_method_placeholder: "Selecione um método de envio",
            shipping_status_placeholder: "Selecione um estado de envio",
            shipping_incident_category_placeholder: "Qualquer tipo",
            shipping_incident_status_placeholder: "Qualquer estado",
            status_placeholder: "Selecione um estado",
            multifilter_bulk: "Pesquisa em pedido em massa ou rastreamento",
            store: "Loja",
            is_delayed: "Atrasados",
            first_delivery_on_time: "Tentativa de entrega no prazo",
            failed_delivery: "Entrega sem sucesso",
            shipping_incident: "Com incidente",
            without_shipping_incident: "Sem incidente",
            unsolved_shipping_incident: "Incidente não resolvido",
        },
        incidents_bar: {
            select_action: "Selecione uma ação",
            deselect_all_orders: "Desmarcar todos os pedidos",
            orders_selected: "Pedidos selecionados",
            update_incident: "Atualizar estado do incidente",
            update_shipping_status: "Atualizar estado de envio",
            update_current_status_for: "Atualizar estado atual para",
            update_current_shipping_status_for: "Atualizar estado de envio atual para",
            dialog: {
                create_title: "Tem certeza de que deseja criar incidente de",
                for: "para",
                orders_selected: "pedidos selecionados?",
                errors_intro: 'Pedidos para los cuales no es posible crear este incidente'
            }
        },
        incidents_container: {
            previous_delivery_date: "Data de entrega anterior",
            new_address: "Nova morada de entrega",
            address: "Endereço",
            number: "Número",
            neighborhood: "Bairro",
            city: "Cidade",
            zip_code: "CEP",
            state: "Estado"
        },        
        list: {
            created_at: "Criado em",
            eta: "Entrega estimada",
            at: "El",
            create_incident: "Criar incidente",
            update_shipping_status: "Atualizar status de envio",
            sure_to_report: "Tem certeza de que deseja criar um incidente de ",
            requiring_monitoring: "Requerem monitoramento",
            no_information: "Sem Status",
            no_pieces: "Sem produtos para enviar",
        },
        shipping_incident : {
            status : {
                PENDING: "Pendente",
                IN_REVIEW: "Sob revisão",
                RESOLVED: "Resolvido",
            },
            category : {
                CANCELED: "Cancelamento solicitado",
                DELAYED: "Entrega atrasada",
                MISSING_REFERENCES: "Completar endereço",
                DAMAGED: 'Pedido avariado',
                MISSING_PRODUCT: 'Faltam produtos',
                FALSE_DELIVERY: 'Entrega incorreta',
                CHANGE_ADDRESS: "Alterar endereço",
                RESCHEDULE: "Reagendar data de entrega",
            },
            resolution: {
                STOLEN: 'Pedido roubado',
                LOST: 'Pedido extraviado',
                NON_REFUNDABLE_DAMAGED: 'Produto avariado não reembolsável',
                RETURNED: 'Retornou ao armazém',
                DELIVERED: 'Pedido entregue',
                REFUNDABLE: 'Reembolsável'
            },
            cant_update: 'Nenhum dos pedidos selecionados tem incidentes criados, então não há itens para atualizar',
            cant_create:{
                order_returned: "O pedido já foi devolvido",
                order_not_shipped_or_returning: "O pedido não foi enviado ou está em processo de devolução",
                unresolved_same_category_incident: "O pedido já tem um incidente deste tipo não resolvido",
                only_damaged_missing_product_false_delivery_incidents_allowed: "Somente incidentes de avaria, faltando produtos ou entrega incorreta são permitidos",
                only_delayed_canceled_missing_references_incidents_allowed: "Somente incidentes de atraso, cancelamento ou endereço incompleto são permitidos",
                remove_stock_order: "Este tipo de pedido não pode criar incidentes",
                temporarily_disabled: "Estamos trabalhando nisso, use o formulário",
            },
            errors: {
                create: {
                    INVALID_CATEGORY_FOR_ORDER_STATUS: 'A categoria de incidente não é válida para o estado do pedido',
                    ORDER_ALREADY_RETURNED: 'O pedido já foi devolvido',
                    ORDER_NOT_SHIPPED: 'O pedido não foi enviado',
                    UNRESOLVED_INCIDENT: 'O pedido já tem um incidente deste tipo não resolvido',
                    ERROR_DESCRIPTION_REQUIRED: 'A descrição do incidente é obrigatória',
                    ERROR_INCIDENT_ALREADY_RESOLVED: 'O incidente já foi resolvido',
                    ERROR_INCOMPATIBLE_ORDER: 'Este tipo de pedido não pode criar incidentes',
                    ORDER_HAS_RETURN:'O Pedido já foi retornou ou retornando.',
                    ORDER_HAS_DELIVERY:'O pedido já foi entregue.',
                    NO_INCIDENT_CREATED: 'O pedido não tem um incidente criado.'
                },
                error_modal: {
                    confirmations: {
                        CREATE_INCIDENT: 'Tem certeza de que deseja criar incidentes do tipo {incident_type} para {order_count} pedidos selecionados?',
                        UPDATE_SHIPPING_STATUS: 'Tem certeza de que deseja atualizar o status de envio {status} para {order_count} pedidos selecionados?',
                        RESOLVE_INCIDENT: 'Tem certeza de que deseja resolver incidentes para os {order_count} pedidos selecionados?',
                        UPDATE_SHIPPING_STATUS: 'Tem certeza de que deseja atualizar o status de envio {shipping_status} para os {order_count} pedidos selecionados?',
                        omit_errors_and_submit: 'Omitir e aceitar o resto',
                        no_valid_elements: 'Nenhum elemento válido.',
                    },
                    messages: {
                        action_not_possible: 'Detectamos {quantity} pedidos para os quais não é possível realizar esta ação',
                        omit_errors_and_submit_question: 'Deseja ignorar esses pedidos e aceitar o restante da seleção?',
                        order_labels_with_errors: 'Tracking dos pedidos com erros',
                        no_valid_elements_tooltip: 'Não é possível realizar esta ação para nenhum dos pedidos selecionados',
                    }
                }
            },
            pod:{
                download: "Baixar",
                received_by: "Recebido por",
                title: "Comprovante de entrega",
                not_provided: "Esta transportadora não fornece comprovante de entrega",
                unavailable: "Não temos comprovante de entrega para este pedido",
                proof: "Comprovante",
                download_success: "Comprovante de entrega baixado com sucesso",
                download_error: "Não foi possível baixar o comprovante de entrega, tente novamente mais tarde",
            },
        },
        show_shipping_incidents: {
            from_date: ". De ",
            created_by: {
                RECIPIENT: "Criado pelo comprador final ",
                CUBBO: "Criado pela Cubbo ",
            },
            info_types:{
                important: "Importante: "
            },
            toggle_description: {
                hide: "Ocultar ",
                show: "Mostrar "
            },
            description_title: {
                MISSING_REFERENCES: "endereço compartilhado",
                OTHER: "evidência compartilhada",
            },
            categories:{
                DELAYED: "Incidente: Atraso na entrega",
                MISSING_REFERENCES: "Incidente: Complementar endereço",
                CANCELED: "Incidente: Cancelar entrega",
                DAMAGED: "Incidente: Produto danificado",
                MISSING_PRODUCT: "Incidente: Produtos faltando",
                FALSE_DELIVERY: "Incidente: Entrega incorreta",
                CHANGE_ADDRESS: "Incidente: Alterar endereço",
                RESCHEDULE: "Incidente: Reagendar data de entrega",
            },
           
            status: {
                PENDING: "Pendente",
                IN_REVIEW: "Em revisão",
                RESOLVED: "Resolvido"
            },

            evidence_type:{
                product_images:"Fotos detalhadas do incidente.",
                label_images:"Fotos da etiqueta de envio.",
                package_images:"Fotos da embalagem."
            }
        },
        packing_material: {
            BUBBLE_WRAP: "Plástico bolha",
            KRAFT_PAPER: "Papel Kraft",
            CORN_EXTRUDATE: "Extrusado de milho"
        }
    },

    cubbo_lastmile_events: {
        order_events: "Histórico",
        created_email: "Por {{user_email}}",
        created_email_xdock: "Por {{user_email}} em {{xdock_name}}",
        no_events: "Não existem eventos disponíveis",
        errors: "Conflito registrado: {{error_codes}}",
        is_collapsed: "Ver tudo",
        is_not_collapsed: "Recolher",
        event_types: {
            CREATED_LABEL: "Guia gerada",
            RECEIVED_AT_XDOCK: "Recebido no Xdock",
            ASSIGN_ROUTE: "Rota atribuída",
            CREATED_DELIVERY_ROUTE: "Rota de entrega gerada",
            GROUPING_PACKAGES: "Empacotamento guia",
            GROUPED_PACKAGES: "Guia empacotada",
            DISPATCHED_TO_MIDDLE_MILE: "Despachado para Middle Mile",
            MIDDLE_MILE_IN_TRANSIT: "Em trânsito pelo Middle Mile",
            DISPATCHED_FOR_DELIVERY: "Despachado para motorista",
            IN_DELIVERY: "Em rota de entrega",
            DELIVERED: "Entregue",
            SHIPPING_INCIDENT: "Incidente",
            FAILED_DELIVERY_ATTEMPT: "Tentativa de entrega falhou",
            RESCHEDULED_DELIVERY: "Entrega reprogramada",
            CANCELLED: "Cancelado",
            RETURNING: "Retornando",
            DISPATCHED_TO_XDOCK: "Despachado para Xdock",
            RETURNED: "Retornado",
            LOST: "Pacote reportado como perdido",
            DAMAGED: "Pacote reportado como danificado",
            STOLEN: "Pacote reportado como roubado",
            SHIPPING_INCIDENT_DELAYED : "Incidente: Atraso na entrega",
            SHIPPING_INCIDENT_MISSING_REFERENCES : "Incidente: Complementar endereço",
            SHIPPING_INCIDENT_MISSING_PRODUCT: "Incidente: Produto faltante",
            SHIPPING_INCIDENT_FALSE_DELIVERY: "Incidente: Entrega incorreta",
            SHIPPING_INCIDENT_CHANGE_ADDRESS: "Incidente: Alterar endereço",
            DISPATCHED_TO_WAREHOUSE: "Despachado para armazém",
            INVALID_LABEL: "Guia inválida",
            PACKAGING_LABEL: "Guia embalada",
            UNPACKED_LABEL: "Guia desembalada",
            PACKED_LABEL: "Guia empacotada"
        },
        error_codes: {
            LABEL_NOT_FOUND: "Guia não encontrada",
            LABEL_WRONG_CARRIER: "Transportadora incorreta",
            LABEL_INVALID_STATUS: "Guia com status incorreto",
            WRONG_OPERATION: "Operação incorreta",
            WRONG_ROUTE: "Rota incorreta",
            NO_ROUTE: "Sem rota",
            CANCELLED: "Pedido cancelado",
            PROCESS_REVERSE_LOGISTICS: "Processando logística reversa",
            REPROGRAMMED: "Entrega reprogramada",
            WRONG_STATUS: "Status incorreto",
            INCIDENT_LOST: "Incidente: Perdido",
            INCIDENT_DAMAGED: "Incidente: Danificado",
            INCIDENT_STOLEN: "Incidente: Roubado",
            LABEL_NOT_RECEIVED: "Guia não recebida no Xdock",
        }
    },

    returns: {
        unidentified: "Não identificado",
        errors: {
            not_belong_to_warehouse: "O retorno da ordem #{{order_number}} pertence ao armazém '{{warehouse_name}}', !SEPARA¡ para processá-lo no armazém correto.",
        }

    },

    generic: {
        order: "Pedido",
        orders: "Pedidos",
        error: "Erro",
        store: "Loja",
        warehouse: "Armazém",
        country: "País",
        status: "Estado",
        from: "De",
        to: "Até",
        usages: "Usos",
        billing_statements: "Cobranças",
        empty_table: "Sem dados para mostrar",
        undefined: "Não definido",
        active: "Ativo",
        pending: "Pendente",
        disabled: "Desativado",
        not_having_current_billing_configuration: "Sem configuração de faturamento",
        having_current_billing_configuration: "Com configuração de faturamento",
        store: "Loja",
        stores: "Lojas",
        store_name: "Nome da loja",
    },

    access_denied: "Desculpe, você não tem permissão para acessar esta página",
    filter: {
        button: "Filtrar",
        reset: "Limpar filtros",
        apply: "Aplicar filtros",
        select: "Selecione um estado",
        from_to: "de-até",
        export: "Exportar",
        in: 'em'
    },

    dialog : {
        yes: "Sim",
        no: 'Não'
    },
    statuses: {
        order_statuses: {
            unknow: "STATUS_NO_DEF",
            initial: "Sincronizando",
            empty: "Pedido vazio",
            pending: "Pendente",
            processing: "Processando",
            shipped: "Enviado",
            returning: "Em devolução a Cubbo",
            returned: "Devolução recebida",
            canceled: "Cancelado",
            error: "Informação Pendente",
            entirely_dropshipping: "Dropshipping",
            backorder: "Sem Estoque",
            unpaid: "Pagamento pendente",
            cancelation_window: "Janela de Cancelamento",
            hold: "Pausado",
            interrupted: "Interrompido",
            waiting_pick_up: "Aguardando coleta",
            awaiting_pick_up: "Retirada pendente",
            picked_up: "Coletado",
            in_warehouse: "Recebido em armazém",
            delayed: "Atrasado",
        },
        tracking_statuses: {
            unknown: "Sem Status",
            in_transit: "Em trânsito",
            shipment_delivered: "Entrega Realizada",
            shipment_with_incident: 'Incidente',
            shipment_canceled: 'Entrega Cancelada',
            shipment_to_be_self_collected: 'Retire no escritório',
            shipment_out_for_delivery: 'Na rota de entrega',
            shipment_with_incident_lost: 'Incidente: Perdido',
            shipment_with_incident_stolen: 'Incidente: Roubado',
            shipment_with_incident_damaged: 'Incidente: Danificado',
            delayed: 'Atrasado',
            shipping_returned: "Devolvido para envio"
        },

        tracking_descriptions: {
            second_try: 'Segunda tentativa',
            third_try: 'Terceira tentativa',
            client_requested: 'Solicitação do comprador',
            missing_references: 'Referências ausentes',
            not_at_home: 'O comprador estava ausente',
            refused_payment: 'O comprador se recusou a pagar',
            refused_package: 'O comprador recusou o pacote',
            cubbo_requested: 'Solicitação do Cubbo',
            carrier_requested: 'Solicitação de operadora',
            stolen: 'Roubaram',
            lost: 'Perda',
            damaged: 'Danificado',
            returned_by_carrier: 'Por entrega sem sucesso',
            returned_by_client: 'Por devolução solicitada',
            in_return: "Em devolução pela transportadora",
        }
    },
    billing: {
        copy_config_filter: "Selecione a loja da qual você deseja copiar a configuração",
        copy_config_duplicate: "Digite o nome da loja da qual deseja duplicar a configuração",
        copy_config_results: "Os primeiros 5 resultados são exibidos, refine sua pesquisa para encontrar a loja desejada",
        copy_config: "Copiar configuração de outra loja",
        create_error_title: "Ocorreu um erro, tente novamente mais tarde",
        create_invalid_content_error_title: "Complete as informações necessárias e/ou corrija para poder criar a configuração de cobrança",
        from_prefix: "Mais de ",
        title: "Cobrança",
        continue: "Continuar",
        save: "Finalizar e salvar configuração",
        create: "Criar configuração",
        disable: "Desativar",
        duplicate: "Duplicar",
        disable_title: "Desativar cobrança no corte",
        already_disabled: "A configuração de cobrança já foi desativada",
        billing_configurations_history: "Histórico de configurações de cobrança",
        billing_custom_services: "Cobranças/Descontos adicionais",
        disable_billing_for_store_success: "A configuração de cobrança será desativada no próximo corte",
        status: {
            ACTIVE: "Ativo",
            DISABLED: "Desativado",
            UNREQUIRED: "Não requerido",
            PENDING: "Pendente"
        },
        stores: {
            start_date: "Início da vigência",
            end_date: "Próximo corte",
            empty: "Não há Lojas para mostrar.",
            about: "Sobre a loja",
            no_data: "Sem informações",
            configure: "Configurar tarifas de cobrança",
            next_cutoff_at: "Data do corte",
            due_next_week: "Vence na próxima semana",
        },
        save_dialog: {
            title: "Criar configuração de cobrança",
            description: "Confirme a criação da configuração de cobrança",
            confirm_button: "Confirmar",
            cancel_button: "Cancelar"
        },
        confirm_disable_dialog: {
            title: "Desativar no próximo corte",
            description: "Confirme a desativação da configuração de cobrança no próximo corte",
            confirm: "Confirmar",
            cancel: "Cancelar"
        },
        confirm_exit_dialog: {
            title: "As alterações serão descartadas. Deseja continuar?"
        },
        tabTitles: {
            billing_date: "Data de faturamento",
            storage: "Armazenamento",
            pick_and_pack: "Pick and Pack",
            shipping_label: "Envios nacionais",
            international_shipping_label: "Envios internacionais",
            replenishment: "Recebimento de inventário",
            returns: "Retornos",
            work_orders: "Maquilas",
            difal: "Difal",
            shipping_insurance: "Seguro de envio",
            packing_material: "Materiais de embalagem"
        },
        ongoing: "Em andamento",
        billed: "Anterior",
        future: "Futura",
        duplicate_configuration: "Duplicar configuração de cobrança",
        show_configuration: "Ver configuração de cobrança",
        store_billing_config: {
            cutoff_interval: {
                title: "Frequência do corte",
                options: {
                    YEAR: "Anual",
                    MONTH: "Mensal",
                    WEEK: "Semanal"
                }
            }
        },
        range_billing_price_config_table: {
            errors:{
                MISSING_FROM_VALUE: "Insira um valor",
                MISSING_TO_VALUE: "Insira um valor",
                MISSING_PRICE_VALUE: "Insira um preço",
                MISSING_UNIT_VALUE: "Selecione uma unidade",
                FROM_IS_GREATER_THAN_TO: "'Deve ser menor que 'Até'",
                FROM_AND_TO_ARE_EQUAL: "'Deve ser menor que 'Até'",
                EXCESS_IS_ONLY_ALLOWED_AT_END: "'Excesso' só pode ser o último elemento",
                MISSING_PRICE_MODE: "",
                INVALID_UNIT_VALUE: "Selecione uma unidade"
            },
            unit_options: {
                PER_UNIT: "Cobrança por peça",
                TOTAL: "Cobrança total",
                EXCESS_PER_UNIT: "Cobrança extra por peça excedida",
                EXCESS_TOTAL: "Cobrança extra fixa por excesso"
            },
            last_row_placeholder: "Sem limite"
        },
        table_headers: {
            from_piece: "De peça",
            to_piece: "Até peça",
            from_weight: "De kg",
            to_weight: "Até kg",
            price: "Preço",
            percentage: "Porcentagem",
            unit: "Unidade de cobrança",
            package: "Pacote",
            packing_material: "Material de embalagem",
            location_type: "Tipo de localização",
            tariff: "Tarifa por localização",
            NORTH_AMERICA: "Preço América do Norte",
            LATIN_AMERICA: "Preço América Latina",
            CARIBBEAN: "Preço Caribe",
            EUROPE: "Preço Europa",
            ASIA: "Preço Ásia",
            AFRICA: "Preço África"
        },
        pick_and_pack_services: {
            picking_title: "Picking",
            insert_title: "Itens promocionais",
            serial_number_title: "Controle de saída por número de série",
            fragile_title: "Embalagem de pacote frágil",
            packages_title: "Embalagens",
            package_title:{
                parcel_bag: "Saco de encomenda",
                jiffy_envelope: "Envelope Jiffy",
                cubbo_box: "Caixa Cubbo",
                customer_box: "Caixa do cliente"
            },
            picking_unit_options: {
                PER_UNIT: "Cobrança por peça",
                TOTAL: "Cobrança por pedido",
                EXCESS_PER_UNIT: "Cobrança extra por peça excedida",
                EXCESS_TOTAL: "Cobrança extra fixa por excesso"
            },
            package_unit_options: {
                TOTAL: "Cobrança por pacote",
            },
            fragile_picking_unit_options: {
                TOTAL: "Cobrança por pacote",
            }
        },
        billing_storage_table: {
            STORAGE_BY_LOCATION: "Cobrança por localização",
            STORAGE_BY_PRODUCT: "Cobrança por peça armazenada",
            missing_picking_standard_error: "ERRO: Localização PICKING STANDARD inexistente",
            category_codes:{
                PICKING_STANDARD: "Regular",
                LOST: "Perdidos",
                DAMAGED: "Danificados",
                PALLET: "Palete",
                RECEPTION: "Recepção",
                PRESALE: "Pré-venda",
                RELOCATION: "Recolocação",
                OFF_PREMISES: "Fora do local",
                PICKING_MINIBIN: "Picking minibin"
            },
            calculated_tariff_tooltip: "Valor calculado com base no preço definido da localização regular",
            tariff_error_message: "Insira a tarifa",
            units: "localização/mês"
        },
        billing_stroage_table_by_unit: {
            unit_options: {
                PER_UNIT: "Cobrança por peça armazenada",
            },
        },
        replenishment_services: {
            replenishment_title: "Recebimento de peças regulares",
            replenishment_lots_title: "Recebimento de peças com controle de lote",
            unit_options: {
                PER_UNIT: "Cobrança por peça recebida",
                TOTAL: "Cobrança por recebimento",
                EXCESS_PER_UNIT: "Cobrança extra por peça excedida",
                EXCESS_TOTAL: "Cobrança extra fixa por excesso"
            },
        },
        difal_services: {
            difal_title: "Difal",
            unit_options: {
                TOTAL: "Cobrança por pedido"
            },
        },
        shipping_insurance_services: {
            shipping_insurance_title: "Seguro de envio",
            unit_options: {
                TOTAL: "Cobrança por pedido"
            },
        },
        packing_material_services: {
            packing_material_title: "Materiais de empacotamiento",
            packing_material_codes: {
                BUBBLE_WRAP: "Plástico bolha",
                CORN_EXTRUDATE: "Extrusado de milho",
                KRAFT_PAPER: "Papel Kraft"
            },
            unit_options: {
                TOTAL: "Cobrança por pacote"
            },
        },
        return_services: {
            return_order_title: "Recebimento de peças regulares",
            return_order_lots_title: "Recebimento de peças com controle de lote",
            return_shipping_label_title: "Guias de retorno",
            use_shipping_cost: "Usar a mesma configuração de preços de envio",
            unit_options: {
                PER_UNIT: "Cobrança por peça recebida",
                TOTAL: "Cobrança por recebimento",
                EXCESS_PER_UNIT: "Cobrança extra por peça excedida",
                EXCESS_TOTAL: "Cobrança extra fixa por excesso"
            },
            return_label_unit_options: {
                TOTAL: "Cobrança por guia",
                EXCESS_PER_UNIT: "Cobrança extra por quilo excedido",
            },
        },
        work_orders_services: {
            work_order_title: "Maquilas",
            unit_options: {
                PER_UNIT: "Cobrança por pessoa/dia"
            }
        },
        national_shipping_label_services: {
            titles:{
                SUPER_SAVER: "Super saver",
                LOCAL: "Local",
                PREMIUM: "Premium",
                ECONOMY: "Econômico",
                PICKUP: "Recolhimento no depósito",
                SP_CAP: "SP Capital",
                SP_INT: "SP Interior",
                RJ_CAP: "RJ Capital",
                RJ_INT: "RJ Interior",
                ES_CAP: "ES Capital",
                ES_INT: "ES Interior",
                MG_CAP: "MG Capital",
                MG_INT: "MG Interior",
                BA_CAP: "BA Capital",
                BA_INT: "BA Interior",
                SE_CAP: "SE Capital",
                SE_INT: "SE Interior",
                PE_CAP: "PE Capital",
                PE_INT: "PE Interior",
                AL_CAP: "AL Capital",
                AL_INT: "AL Interior",
                PB_CAP: "PB Capital",
                PB_INT: "PB Interior",
                RN_CAP: "RN Capital",
                RN_INT: "RN Interior",
                CE_CAP: "CE Capital",
                CE_INT: "CE Interior",
                PI_CAP: "PI Capital",
                PI_INT: "PI Interior",
                MA_CAP: "MA Capital",
                MA_INT: "MA Interior",
                PA_CAP: "PA Capital",
                PA_INT: "PA Interior",
                AP_CAP: "AP Capital",
                AP_INT: "AP Interior",
                AM_CAP: "AM Capital",
                AM_INT: "AM Interior",
                RR_CAP: "RR Capital",
                RR_INT: "RR Interior",
                AM_INT_2: "AM Interior 2",
                AC_CAP: "AC Capital",
                AC_INT: "AC Interior",
                DF_CAP: "DF Capital",
                GO_CAP: "GO Capital",
                GO_INT: "GO Interior",
                TO_CAP: "TO Capital",
                TO_INT: "TO Interior",
                MT_CAP: "MT Capital",
                MT_INT: "MT Interior",
                MS_CAP: "MS Capital",
                MS_INT: "MS Interior",
                PR_CAP: "PR Capital",
                PR_INT: "PR Interior",
                SC_CAP: "SC Capital",
                SC_INT: "SC Interior",
                RS_CAP: "RS Capital",
                RS_INT: "RS Interior",
            },
            extended_zone_titles:{
                SUPER_SAVER: "Zona estendida Super saver",
                LOCAL: "Zona estendida Local",
                PREMIUM: "Zona estendida Premium",
                ECONOMY: "Zona estendida Econômica",
                SP_CAP: "Zona estendida SP Capital",
                SP_INT: "Zona estendida SP Interior",
                RJ_CAP: "Zona estendida RJ Capital",
                RJ_INT: "Zona estendida RJ Interior",
                ES_CAP: "Zona estendida ES Capital",
                ES_INT: "Zona estendida ES Interior",
                MG_CAP: "Zona estendida MG Capital",
                MG_INT: "Zona estendida MG Interior",
                BA_CAP: "Zona estendida BA Capital",
                BA_INT: "Zona estendida BA Interior",
                SE_CAP: "Zona estendida SE Capital",
                SE_INT: "Zona estendida SE Interior",
                PE_CAP: "Zona estendida PE Capital",
                PE_INT: "Zona estendida PE Interior",
                AL_CAP: "Zona estendida AL Capital",
                AL_INT: "Zona estendida AL Interior",
                PB_CAP: "Zona estendida PB Capital",
                PB_INT: "Zona estendida PB Interior",
                RN_CAP: "Zona estendida RN Capital",
                RN_INT: "Zona estendida RN Interior",
                CE_CAP: "Zona estendida CE Capital",
                CE_INT: "Zona estendida CE Interior",
                PI_CAP: "Zona estendida PI Capital",
                PI_INT: "Zona estendida PI Interior",
                MA_CAP: "Zona estendida MA Capital",
                MA_INT: "Zona estendida MA Interior",
                PA_CAP: "Zona estendida PA Capital",
                PA_INT: "Zona estendida PA Interior",
                AP_CAP: "Zona estendida AP Capital",
                AP_INT: "Zona estendida AP Interior",
                AM_CAP: "Zona estendida AM Capital",
                AM_INT: "Zona estendida AM Interior",
                AM_INT_2: "Zona estendida AM Interior 2",
                RR_CAP: "Zona estendida RR Capital",
                RR_INT: "Zona estendida RR Interior",
                AC_CAP: "Zona estendida AC Capital",
                AC_INT: "Zona estendida AC Interior",
                DF_CAP: "Zona estendida DF Capital",
                DF_INT: "Zona estendida DF Interior",
                DF_INT_2: "Zona estendida DF Interior 2",
                GO_CAP: "Zona estendida GO Capital",
                GO_CAP_2: "Zona estendida GO Capital 2",
                GO_INT: "Zona estendida GO Interior",
                RO_CAP: "Zona estendida RO Capital",
                RO_INT: "Zona estendida RO Interior",
                RO_INT_2: "Zona estendida RO Interior 2",
                RO_INT_3: "Zona estendida RO Interior 3",
                TO_CAP: "Zona estendida TO Capital",
                TO_INT: "Zona estendida TO Interior",
                MT_CAP: "Zona estendida MT Capital",
                MT_INT: "Zona estendida MT Interior",
                MS_CAP: "Zona estendida MS Capital",
                MS_INT: "Zona estendida MS Interior",
                PR_CAP: "Zona estendida PR Capital",
                PR_INT: "Zona estendida PR Interior",
                SC_CAP: "Zona estendida SC Capital",
                SC_INT: "Zona estendida SC Interior",
                RS_CAP: "Zona estendida RS Capital",
                RS_INT: "Zona estendida RS Interior"
            },
            unit_options: {
                TOTAL: "Cobrança por guia",
                EXCESS_PER_UNIT: "Cobrança extra por quilo excedido",
            },
            pickup_unit_options: {
                TOTAL: "Cobrança por pedido",
            }
        },
        international_shipping_label_services: {
            unit_options: {
                TOTAL: "Cobrança por guia",
                EXCESS_PER_UNIT: "Cobrança extra por quilo excedido"
            }
        },
        custom_services: {
            create_current_title: "Criar cobrança/desconto adicional para o corte atual",
            create_button: "Criar cobrança/desconto adicional",
            types: {
                discount: "Desconto",
                charge: "Cobrança"
            },
            no_services: "Sem informações",
            create_custom_service: {
                init_error: "Ocorreu um erro ao inicializar, tente novamente mais tarde",
                create_error: "Ocorreu um erro durante a criação, tente novamente mais tarde",
                store_name: "Loja: ",
                warehouse_name: "Depósito: ",
                is_discount: "É desconto?",
                title: "Título",
                title_placeholder: "Insira um título",
                description: "Descrição",
                description_placeholder: "Insira uma descrição",
                amount_discount: "Valor do desconto",
                amount_placeholder_discount: "Insira um valor de desconto",
                amount_charge: "Valor da cobrança",
                amount_placeholder_charge: "Insira um valor de cobrança",
                no_amount: "O valor não pode ser 0",
                create_button: "Criar serviço personalizado",
                remaining_text_length: "Caracteres restantes disponíveis: ",
                confirm_create_dialog:{
                    title_DISCOUNT: "Criar desconto adicional",
                    title_CHARGE: "Criar cobrança adicional",
                    description_DISCOUNT: "Confirme a criação do desconto adicional para a loja {STORE_NAME} no depósito {WAREHOUSE_NAME}",
                    description_CHARGE: "Confirme a criação da cobrança adicional para a loja {STORE_NAME} no depósito {WAREHOUSE_NAME}",
                    confirm_button: "Confirmar",
                    cancel_button: "Cancelar"
                }
            },
            list_custom_services: {
                table_columns: {
                    id: "ID",
                    billed_total_price: "Valor",
                    type: "Tipo",
                    title: "Título",
                    description: "Descrição"
                },
                init_error: "Ocorreu um erro ao inicializar, tente novamente mais tarde",
                table_filters: {
                    title: "Título",
                    title_placeholder: "Buscar por título",
                    billed_total_price: "Valor",
                    greater_or_equal_than_billed_total_price: "Maior ou igual ao valor",
                    less_or_equal_than_billed_total_price: "Menor ou igual ao valor",
                    is_discount: "É desconto",
                    is_charge: "É cobrança",
                    description: "Descrição"
                }
            }
        }
    },
    work_orders: {
        work_order_details: {
            work_orders: "Maquilas",
            work_order_details: "Detalhes da solicitação de maquila",
            work_order_number: "Nº de maquila",
            solicitant: "Solicitante",
            work_order_status: "Estado da maquila",
            on_date: "Em",
            comments_to_the_brand: "Comentários para a marca",
            next_status: "Ao salvar os dados, o estado da maquila será atualizado para",
            processing_work_order: "Processando maquila",
            product_origin: "Origem do material necessário",
            rem_order: "Retirada de inventário",
            no_rem_order: "Esta maquila não possui pedido de retirada de inventário",
            work_order: "Solicitação de maquila",
            work_order_type: "Tipo de processo",
            notes: "Notas",
            instruction: "Instrução",
            activity: "Atividade",
            replenishment: "Entrada de produto maquilado",
            replenishment_id: "Recibo de inventário de produto maquilado",
            replenishment_note: "A partir deste recibo de inventário, você pode acompanhar o progresso da entrada do produto maquilado em seu inventário",
            resolve_order: "Resolver solicitação",
            notification_message: {
                success_update_message: "Estado da maquila atualizado com sucesso",
                error_update_message: "Erro ao atualizar estado da maquila"
            },
            work_order_statuses: {
                REQUESTED: "Solicitada",
                IN_VALIDATION: "Em validação",
                SCHEDULED: "Próxima a iniciar",
                IN_PROGRESS: "Em progresso",
                COMPLETED: "Completada",
                REJECTED: "Rejeitada",
                CANCELED: "Cancelada"
            },
            work_order_types: {
                LABELING_PLASTISEAL: "De etiquetagem ou plastificação",
                PRODUCT_VALIDATION: "De validação de produto",
                KIT_ASSEMBLY_SHRINKWRAP: "De montagem de kits e/ou aplicação de termoencolhimento",
                PACKAGING: "De embalagem",
                PRODUCT_SEGREGATION: "De segregação de produto"
            },
            work_order_product_origin: {
                ALL_INVENTORY_AT_WAREHOUSE: "Todo o material está armazenado no meu inventário na Cubbo disponível para venda",
                MATERIAL_IN_TRANSIT_TO_WAREHOUSE: "O material está a caminho do armazém",
                PARTIAL_MATERIAL_INVENTORY_IN_TRANSIT_TO_WAREHOUSE: "Parte do material está armazenado na Cubbo e parte está a caminho do armazém"
            },
            work_order_card: {
                update_work_order_status: "Atualizar status da maquila",
                save_button: "Salvar atualização",
                start_progress_button: "Iniciar progresso",
                complete_work_order_button: "Concluir maquila",
                reject_work_order_button: "Rejeitar maquila",
                complete_the_information_requested: "Complete as informações quando desejar iniciar a validação operacional da maquila",
                validate_order: "A solicitação possui tudo o que é necessário para passar pela validação operacional?",
                confirm_validation: "Sim, aceitar solicitação",
                reject_validation: "Não, rejeitar solicitação",
                complete_the_information_in_validation: "Complete as informações assim que a validação da maquila for concluída",
                necessary_pieces: "Todos as peças necessárias estão disponíveis para processamento da maquila?",
                estimated_operator_quantity: "Quantas pessoas se estima que serão designadas para processar a maquila?",
                estimated_labor_days_quantity: "Dias estimados de processamento",
                scheduled_order: "Esta solicitação foi agendada para próxima a iniciar",
                complete_the_information_in_progress: "Complete as informações assim que a maquila for concluída",
                confirm_operator_quantity: "Confirme o total de pessoas usadas na maquila",
                estimated_labor_days_quantity: "Confirme os dias estimados de processamento",
                completed_work_order: "Esta maquila foi concluída",
                rejected_work_order: "Esta maquila foi rejeitada",
                canceled_work_order: "Esta maquila foi cancelada",
                files_placeholder: "Clique aqui ou arraste seus arquivos PDF, Excel ou imagens"
            }
        },
        work_order_list: {
            header: {
                work_order_id: "Nº MAQUILA",
                customer: "CLIENTE",
                work_order_type: "TIPO",
                status: "ESTADO",
                created_at: "DATA DE SOLICITAÇÃO"
            },
            filters: {
                work_order_id: "Nº maquila",
                status: "Estado",
                work_order_type: "Tipo de maquila"
            },
            footer: {
                no_work_orders: "Não há maquilas para mostrar",
                work_order: "maquilas"
            },
            queued: "Em fila",
            view_work_order: "Ver maquila",
            on_date: "Em"
        }
    },
    replenishment_labels: {
        dialog_title: "Imprimir ID de recibo de inventário",
        label_title: "Identificador de recibo de inventário",
        label_instructions: "Imprima e cole esta etiqueta no pacote retornado para identificar o retorno ao realizar o armazenamento.",
        store: "Loja",
        warehouse: "Armazém",
        replenishment_id: "ID de recibo"
    },
    replenishment: {
        expected_quantity: "peças pendentes de receber",
        error_unexpected_stock_start: "O máximo de peças a receber por produto é a quantidade esperada. ",
        error_unexpected_stock_middle: "Ajuste a quantidade a ser recebida, limitando as peças pendentes a receber",
        error_unexpected_stock_end: " e separe as peças sobrantes para processá-las em outro recibo."
    }
}