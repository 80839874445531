import { Button } from "../../../../components";

const MissingPickingPiecesSection = ({onSubmitOrderForReview, isSubmitOrderForReviewDisabled, isHidden=false}) => {
    if(isHidden) return (<></>)

    return (
        <section className="bg-white rounded-lg p-4 flex flex-col gap-2 text-lg">
            <h2 className="mb-0 text-2xl font-bold">{'¿Faltaron piezas por pickear?'}</h2>
            <p className="mb-0">
                <span className="font-bold">{'Escanea todos los productos del tote. '}</span>
                {'Si tras finalizar, aún hay piezas faltantes, envía el pedido a revisión.'}
            </p>
            <Button
                className="w-full"
                onClick={onSubmitOrderForReview}
                disabled={isSubmitOrderForReviewDisabled}
                tabIndex={-1}
            >
                {'Enviar pedido a revisión'}
            </Button>
        </section>
    )
}

export default MissingPickingPiecesSection
