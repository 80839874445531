import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { cloneDeep } from "lodash"
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline"

import { findOrCreateBillingService, getCurrency } from "../utils"
import { SERVICE_TYPES } from "../constants"
import RangeBillingPriceConfigTable from "../RangeBillingPriceConfigTable"
import { UNITS_IDS } from "../RangeBillingPriceConfigTable/constants"

const ReturnServices = ({storeBillingServices, onUpdateStoreBillingServices, onIsValid, storeId, warehouse}) => {
    const { i18n } = useTranslation()

    const tableHeaders = [
        {label: i18n.t("billing.table_headers.from_piece"), id: 'from'},
        {label: i18n.t("billing.table_headers.to_piece"), id: 'to'},
        {label: i18n.t("billing.table_headers.price"), id: 'price'},
        {label: i18n.t("billing.table_headers.unit"),id: 'unit'},
        {label: null,id: 'actions'}
    ]

    const tableReturnShippingLabelsHeaders = [
        {label: i18n.t("billing.table_headers.from_weight"), id: 'from'},
        {label: i18n.t("billing.table_headers.to_weight"), id: 'to'},
        {label: i18n.t("billing.table_headers.price"), id: 'price'},
        {label: i18n.t("billing.table_headers.unit"),id: 'unit'},
        {label: null,id: 'actions'}
    ]

    const [isReturnOrderValid, setIsReturnOrderValid] = useState(false)
    const [isReturnOrderLotsValid, setIsReturnOrderLotsValid] = useState(false)
    const [isReturnShippingLabelValid, setIsReturnShippingLabelValid] = useState(false)
    const [useShippingCost, setUseShippingCost] = useState(false)

    const returnOrderService = findOrCreateBillingService({serviceType: SERVICE_TYPES.SERVICE_RETURN_ORDER, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id})
    const returnOrderLotsService = findOrCreateBillingService({serviceType: SERVICE_TYPES.SERVICE_RETURN_ORDER_LOTS, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id})
    const returnShippingLabelService = findOrCreateBillingService({serviceType: SERVICE_TYPES.SERVICE_RETURN_SHIPPING_LABEL, billingServices: storeBillingServices, currency: getCurrency(warehouse?.country), storeId: storeId, warehouseId: warehouse?.id})
    const returnMirrorShippingLabelService = {
        store_id: storeId,
        warehouse_id: warehouse?.id,
        service_type: SERVICE_TYPES.SERVICE_RETURN_MIRROR_SHIPPING_LABEL,
        service_type_category: "NONE_APPLIES",
        currency: getCurrency(warehouse?.country),
        price_configuration: [{from:0,to:null,price:0.0,price_mode: UNITS_IDS.TOTAL}]
    }

    const returnOrderTableData = returnOrderService?.price_configuration.map(priceRange => {
        return {
            from: {value: priceRange.from, isDisabled: true},
            to: {value: priceRange.to},
            price: {value: priceRange.price},
            price_mode: {value: priceRange.price_mode},
            excess: {value: priceRange.excess},
            range_exclusion: {value: priceRange.range_exclusion},
        }
    })

    const returnOrderLotsTableData = returnOrderLotsService?.price_configuration.map(priceRange => {
        return {
            from: {value: priceRange.from, isDisabled: true},
            to: {value: priceRange.to},
            price: {value: priceRange.price},
            price_mode: {value: priceRange.price_mode},
            excess: {value: priceRange.excess},
            range_exclusion: {value: priceRange.range_exclusion},
        }
    })

    const priceModesReturnLabels = [UNITS_IDS.TOTAL, UNITS_IDS.EXCESS_PER_UNIT]
    const returnShippingLabelTableData = returnShippingLabelService?.price_configuration.map(priceRange => {
        return {
            from: {value: priceRange.from, isDisabled: true},
            to: {value: priceRange.to},
            price: {value: priceRange.price},
            price_mode: {value: priceRange.price_mode || UNITS_IDS.TOTAL},
            excess: {value: priceRange.excess},
            range_exclusion: {value: priceRange.range_exclusion},
        }
    })

    const unitOptionsLabels = {
        PER_UNIT: i18n.t("billing.return_services.unit_options.PER_UNIT"),
        TOTAL: i18n.t("billing.return_services.unit_options.TOTAL"),
        EXCESS_PER_UNIT: i18n.t("billing.return_services.unit_options.EXCESS_PER_UNIT"),
        EXCESS_TOTAL: i18n.t("billing.return_services.unit_options.EXCESS_TOTAL")
    }

    const returnLabelUnitOptionsLabels = {
        TOTAL: i18n.t("billing.return_services.return_label_unit_options.TOTAL"),
        EXCESS_PER_UNIT: i18n.t("billing.return_services.return_label_unit_options.EXCESS_PER_UNIT"),
    }

    const handleOnUpdateData = (serviceType, newTableData, service) => {
        let newStoreBillingServices = cloneDeep(storeBillingServices)

        const newPriceConfiguration = newTableData.map(priceRangeData => {
            return {
                from: priceRangeData.from.value,
                to: priceRangeData.to.value,
                price: priceRangeData.price.value,
                price_mode: priceRangeData.price_mode.value,
                excess: priceRangeData.excess.value,
                range_exclusion: priceRangeData.range_exclusion.value
            }
        })

        const serviceExists = newStoreBillingServices.some(({service_type}) => service_type === serviceType)

        if(serviceExists){
            newStoreBillingServices = newStoreBillingServices.map(billingService => {
                if(billingService.service_type === serviceType){
                    billingService.price_configuration = newPriceConfiguration
                }
                return billingService
            })
        } else{
            const newService = cloneDeep(service)
            newService.price_configuration = newPriceConfiguration
            newStoreBillingServices.push(newService)
        }

        onUpdateStoreBillingServices(newStoreBillingServices)
    }

    const handleOnIsValid = (serviceType, isValid) => {
        switch(serviceType){
            case SERVICE_TYPES.SERVICE_RETURN_ORDER:
                setIsReturnOrderValid(isValid)
                break

            case SERVICE_TYPES.SERVICE_RETURN_ORDER_LOTS:
                setIsReturnOrderLotsValid(isValid)
                break

            case SERVICE_TYPES.SERVICE_RETURN_SHIPPING_LABEL:
                setIsReturnShippingLabelValid(isValid)
                break

            default:
                break
        }
    }

    function handleSwitchReturnShippingLabelService() {
        // Remove any objects with service_type "RETURN_SHIPPING_LABEL" OR "RETURN_MIRROR_SHIPPING_LABEL"
        const filteredServices = storeBillingServices.filter(service => ![SERVICE_TYPES.SERVICE_RETURN_SHIPPING_LABEL, SERVICE_TYPES.SERVICE_RETURN_MIRROR_SHIPPING_LABEL].includes(service.service_type));
        if (useShippingCost == false) {
            filteredServices.push(returnMirrorShippingLabelService);
            onUpdateStoreBillingServices(filteredServices)
            handleOnIsValid(SERVICE_TYPES.SERVICE_RETURN_SHIPPING_LABEL, true)
        }
        else {
            filteredServices.push(returnShippingLabelService);
            onUpdateStoreBillingServices(filteredServices)
        }
        setUseShippingCost(!useShippingCost)
    }

    useEffect(() => {
        const newValidStatus = isReturnOrderValid && isReturnOrderLotsValid && isReturnShippingLabelValid
        onIsValid(newValidStatus)
    }, [isReturnOrderValid, isReturnOrderLotsValid, isReturnShippingLabelValid])

    return (
        <div className="flex flex-col gap-5">
            <div>
                <div className="flex gap-1">
                    <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.return_services.return_order_title")}</h2>

                    {isReturnOrderValid ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                    ) : (
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                    )}
                </div>

                <RangeBillingPriceConfigTable
                    headers={tableHeaders}
                    priceConfiguration={returnOrderTableData}
                    currency={getCurrency(warehouse?.country)}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_RETURN_ORDER, newTableData, returnOrderService)}
                    onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_RETURN_ORDER, isValid)}
                    unitOptionsLabels={unitOptionsLabels}
                />
            </div>

            <div>
                <div className="flex gap-1">
                    <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.return_services.return_order_lots_title")}</h2>

                    {isReturnOrderLotsValid ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                    ) : (
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                    )}
                </div>

                <RangeBillingPriceConfigTable
                    headers={tableHeaders}
                    priceConfiguration={returnOrderLotsTableData}
                    currency={getCurrency(warehouse?.country)}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_RETURN_ORDER_LOTS, newTableData, returnOrderLotsService)}
                    onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_RETURN_ORDER_LOTS, isValid)}
                    unitOptionsLabels={unitOptionsLabels}
                />
            </div>

            <div>
                <div className="flex gap-1">
                    <h2 className="text-xl text-gray-500"><span className="text-red-500">*</span>{i18n.t("billing.return_services.return_shipping_label_title")}</h2>

                    {isReturnShippingLabelValid ? (
                        <CheckCircleIcon className="h-5 w-5 text-green-500"/>
                    ) : (
                        <ExclamationCircleIcon className="h-5 w-5 text-yellow-500"/>
                    )}
                </div>

                {/* {console.log("RET: storeBillingServices:", storeBillingServices)}*/}
                <div className="relative flex items-start mb-3">
                    <div className="flex items-center h-5">
                        <input
                            id="use_shipping_cost"
                            type="checkbox"
                            checked={useShippingCost}
                            onChange={event => handleSwitchReturnShippingLabelService()}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                    </div>
                    <div className="ml-2 text-sm">
                        <span className="text-gray-500">{i18n.t("billing.return_services.use_shipping_cost")}</span>
                    </div>
                </div>
                
                {!useShippingCost &&
                <RangeBillingPriceConfigTable
                    headers={tableReturnShippingLabelsHeaders}
                    priceConfiguration={returnShippingLabelTableData}
                    currency={getCurrency(warehouse?.country)}
                    onUpdatePriceConfiguration={newTableData => handleOnUpdateData(SERVICE_TYPES.SERVICE_RETURN_SHIPPING_LABEL, newTableData, returnShippingLabelService)}
                    onIsValid={(isValid) => handleOnIsValid(SERVICE_TYPES.SERVICE_RETURN_SHIPPING_LABEL, isValid)}
                    availableUnitOptions={priceModesReturnLabels}
                    unitOptionsLabels={returnLabelUnitOptionsLabels}
                />}
            </div>
        </div>
    )
}

export default ReturnServices